@use '_scss/settings';
@use '_scss/07-plugins/mq' with (
  $breakpoints: settings.$mq-breakpoints
);

.ns-mzh {
  --white: #fff;
  --primary-color: #23387c;
  --focus-color: #3c84f0;

  --media-control-background: var(--primary-color);

  .c-panel--nav,
  .c-page__header--fixed,
  .c-sidebar {
    display: none;
  }

  .c-page__content-horizontal-flow {
    margin-left: 0;
  }

  .c-page__content-horizontal-flow > .c-panel {
    min-height: 100dvh;
    margin-top: 0;
  }

  .shown-at-tiny {
    display: none;

    @include mq.mq($from: tiny) {
      display: flex;
    }

    @include mq.mq($from: medium) {
      display: none;
    }
  }

  .shown-till-tiny {
    display: flex;

    @include mq.mq($from: tiny) {
      display: none;
    }
  }

  //   @include mq($until: 799px) {
  .c-page__content-horizontal-flow {
    max-height: 100dvh;
  }

  .c-page__header.c-page__header--fixed,
  .c-panel .c-breadcrumbs {
    display: none;
  }

  .c-panel__title {
    justify-content: flex-start !important;
    align-items: flex-start !important;

    > .c-button--tiny {
      margin-left: -6px;
      margin-right: 6px;
      margin-top: 3px;

      .c-icon use {
        fill: var(--primary-color);
        stroke: var(--primary-color);
      }

      span {
        text-transform: none;
        font-size: 14px;
        color: var(--primary-color);
        margin-left: 8px;
      }
    }

    .c-menu__wrapper.c-panel__title-button {
      margin-left: auto;
    }
  }

  .c-panel__title-buttons-group {
    margin-left: auto;
  }

  .p-tabs .react-tabs__tab--selected::after {
    background: var(--primary-color);
  }

  .c-list__item {
    opacity: 1;
  }

  mux-player::part(center play button) {
    background-color: var(--primary-color);
    width: 72px;
    height: 72px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .c-button--primary,
  .c-list__item-icon {
    background-color: var(--primary-color);
  }

  .c-button--primary:focus {
    outline-color: var(--focus-color);
  }

  .c-button--tiny .c-icon {
    color: var(--primary-color);
  }

  .c-list__item-small-editing-area.c-list__item-wrapping-note-preview {
    > p:not([class]) {
      opacity: 1 !important;
    }

    > .c-button__group {
      display: none;
    }
  }

  .c-button__group-fixed-bottom {
    width: 100%;
    background: var(--white);
    bottom: 0;
    padding: 12px;
    left: 0;
    right: 0;
    justify-content: center;
    box-shadow: 0 0px 10px -8px;

    .c-button .c-icon {
      display: none;
    }

    .c-button {
      width: 100%;
      text-align: center;
      justify-content: center;
      text-transform: capitalize;
      font-size: 14px;

      border-radius: 4px !important;

      &::after {
        content: attr(data-label);
      }
    }

    .c-button--disabled.c-button {
      opacity: 0.5;
      background-color: var(--primary-color) !important;
      color: var(--white) !important;
    }

    .c-button--disabled.c-button:first-child {
      background-color: var(--white) !important;
      color: var(--primary-color) !important;
    }

    .c-button:first-child {
      background-color: var(--white);
      color: var(--primary-color);
      outline: 1px solid currentColor;
      outline-offset: -1px;
    }
  }
  //   }
}
