@use '_scss/settings';
@use '_scss/07-plugins/mq' with (
  $breakpoints: settings.$mq-breakpoints
);

.p-tabs {
  background-color: inherit;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  .c-loader {
    background: rgba(255, 255, 255, 0);
  }

  .react-tabs {
    background-color: inherit;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  .react-tabs__tab-list {
    display: flex;
    margin: 0;
    overflow-x: auto;
    background-color: inherit;
    z-index: 21;
    position: relative;

    &::after {
      content: '';
      border-bottom: 1px solid settings.$color-neutral;
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: -1;
    }
  }

  .react-tabs__tab {
    position: relative;
    display: inline-flex;
    align-items: center;

    list-style: none;
    user-select: none;
    cursor: pointer;

    padding: settings.$spacing-medium 0 (settings.$spacing-tiny * 3);
    margin: 0;

    text-decoration: none;
    color: settings.$color-primary-dark;
    font-family: settings.$font-family-bold;

    white-space: nowrap;

    a {
      text-decoration: none;
      color: inherit;
      display: inline-flex;
      align-items: center;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: settings.$transparent;
        transition: all 0.2s ease-in-out;
      }

      &:hover,
      &:focus {
        &::after {
          content: '';
          background: settings.$color-primary-dark-15;
        }
      }
    }

    .p-tabs__link-disabled {
      display: inline-flex;
      align-items: center;
    }

    &:not(:first-child) {
      margin-left: settings.$spacing-large;
      padding-right: settings.$spacing-tiny;
    }

    &:focus,
    &:focus-within {
      outline: none;
      box-shadow: 0 3px 5px -4px settings.$color-primary;
    }

    @include settings.font-size(13px, 1.2);
  }

  &.p-tabs--click-on-link .react-tabs__tab {
    padding: 0;

    .p-tabs__link-disabled,
    a {
      padding: settings.$spacing-medium 0 (settings.$spacing-tiny * 3);
    }
  }

  .react-tabs__tab--selected {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: settings.$color-primary;
    }
  }

  .react-tabs__tab-panel {
    display: none;
    padding: settings.$spacing-small 0;

    &--selected {
      display: block;
    }
  }

  .react-tabs__tab--disabled {
    opacity: 0.5;
    cursor: default;
  }

  .c-panel__tools {
    width: 100%;
    flex: 0 1 auto;
  }

  .react-tabs__tab-panel--selected {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    overflow-y: auto;
    margin: 0 -#{settings.$spacing-medium};
    padding: 0 settings.$spacing-medium;
    position: relative;

    > .c-list .c-list__item-small {
      background-color: settings.$color-neutral-light;
    }

    > .o-flex.o-flex--column:first-child {
      .c-list {
        overflow: visible;
      }
    }
  }

  &:not(.p-tabs--full-width) .react-tabs__tab-panel--selected {
    > :first-child:not(.c-card):not(.c-loader),
    .p-tabs__active-tab-content-wrapper {
      margin: 0 -#{settings.$spacing-medium};
      padding: 0 settings.$spacing-medium;
    }
  }
}

.p-tabs--full-width {
  .react-tabs__tab-panel--selected {
    > * {
      overflow: auto !important;
    }
  }
}

.p-tabs--no-padding {
  padding: 0;
}

// TODO fabian (refactoring): use `c-panel__body--full-width` instead `tabs--full-width`???
.p-tabs--full-width {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    flex: 0;
  }

  .react-tabs {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  .react-tabs__tab-panel {
    display: none;
    flex: 1 0 auto;
    padding: settings.$spacing-medium settings.$spacing-medium 0;
  }

  .react-tabs__tab-panel--selected {
    flex-direction: row;
    display: flex;
  }

  .react-tabs__tab-list {
    flex: 0 0 auto;

    .react-tabs__tab {
      a {
        &::after {
          bottom: 0;
        }
      }
    }
    .react-tabs__tab--selected {
      &:after {
        bottom: 0;
      }
    }
  }

  .c-card__header-text-group > * {
    flex: 1 1 100%;
  }

  .c-card__header-text-group > .c-card__header-buttons {
    flex: 1 0 30%;
  }

  .c-card__header-text-group {
    display: flex;
  }

  .c-panel {
    margin: 0;
    padding: 0;
    border-right: 0;
    background-color: settings.$color-neutral-x-light;
    display: block;
    overflow: visible;
    flex-direction: column;

    &:first-child {
      padding-right: settings.$spacing-small;
    }

    &:last-child {
      padding-left: settings.$spacing-small;
    }
  }

  .c-card--center-center {
    flex: 1;
  }

  .c-card--secondary {
    padding: 0;
    margin: 0;
    flex-direction: column;

    &:not(.c-card--empty) {
      flex: 0;
      display: flex;

      &:last-child {
        flex: 1;
      }
    }

    .c-input__group > .c-input__group {
      margin-bottom: 0;
    }

    .c-input--inline .c-input__label {
      margin-right: 0;
      min-width: 110px;
    }

    .c-input--inline .c-input__group--radiobutton:not(:last-child) {
      margin-right: settings.$spacing-medium * 1.5;
    }

    .c-input--inline .p-select-container {
      flex: 1 0 auto;
      margin-left: settings.$spacing-medium;
      max-width: calc(100% - 107px);
    }

    .c-input--inline.o-flex--align-start {
      align-items: flex-start;
    }

    .o-flex--align-start .c-input__label {
      margin-top: settings.$spacing-small + settings.$spacing-tiny;
    }

    > *:not(form) {
      padding: settings.$spacing-medium settings.$spacing-medium 0;
      margin: 0;
      margin-bottom: 0 !important;

      overflow: visible;

      &:last-child {
        padding-bottom: settings.$spacing-medium;
      }
    }
  }

  .c-card--secondary.c-card--empty {
    height: 180px;
    flex: 0 0 auto;

    > .c-card__body.c-card__body--empty {
      margin: auto !important;
    }
  }

  .c-card--secondary + .c-card--secondary {
    margin-top: settings.$spacing-medium;
  }

  .c-card__header {
    flex-direction: column;
    align-items: stretch;
    max-height: none;
    overflow: visible;

    .c-card__header-title + :not(.c-card__header-buttons) {
      margin-top: settings.$spacing-medium;
    }
  }

  .c-card__body {
    display: flex;
    flex-direction: column;
    flex: 0;
  }

  .c-card__body--tools {
    padding: 0;
  }

  .c-card--multiple-body {
    display: flex;
    flex-direction: column;

    // .c-card__body > *:last-child:not(.c-message) {
    //   margin-bottom: settings.$spacing-medium;
    // }

    .c-card__body--empty {
      flex: 1 0 200px;
      display: flex;
      justify-content: center;
      align-items: center;

      > .c-message {
        margin: settings.$spacing-large auto auto;
      }
    }

    > .c-card__body-separator {
      flex: 0;
    }
  }

  .c-message {
    margin: 0 auto;
  }

  .c-panel__body {
    flex: 1 0 auto;
    display: flex;
    height: auto;
    min-height: 100%;
  }

  .c-panel .c-list {
    min-height: 0;
    padding: 0;
    margin-top: settings.$spacing-small + settings.$spacing-medium;

    .c-list__header {
      height: auto;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .c-panel .c-list.c-list--draggable {
    max-height: none;
    min-height: 0;
  }

  .c-list__body {
    margin-bottom: 0;
  }

  .c-list__item:last-child {
    margin-bottom: 0;
  }

  // Feedback:
  .c-card--progress-bar.c-card--secondary {
    padding: settings.$spacing-medium 0 0;
    background-color: settings.$color-neutral-x-light;

    .c-card__header {
      padding: 0;

      & + .c-card__body--secondary {
        border-top: none;
      }
    }

    .c-card__body {
      padding: 0;
      background-color: settings.$color-neutral-x-light;
      flex: 1 0 auto;

      .c-button.u-margin-vertical-medium.c-button--grey.c-button {
        align-self: center;
      }
    }

    .c-list {
      min-height: 0;

      &:last-child {
        padding: settings.$spacing-medium settings.$spacing-medium 0;
        background-color: settings.$color-neutral-light;
      }
    }

    .c-list__item {
      max-height: none;
    }

    .c-list__item-small .c-list__item-text:first-child {
      white-space: normal;
    }

    // Refactored: .r-list__item-order
    // .c-badge--rank {
    //   flex: 1 0 auto;
    // }

    .c-list__item-small .c-list__item-label {
      flex: 1 0 auto;
      max-width: calc(100% - 28px);
      padding-left: settings.$spacing-tiny;
    }

    .c-list__item-text ~ .c-list__item-text:last-child {
      align-self: flex-start;
    }

    .c-card__body ~ .c-card__footer {
      background-color: settings.$color-neutral-light;
      padding: settings.$spacing-medium;
    }
  }
}

// Using on AnnotateVideo
.p-tabs--full-width-b {
  .react-tabs__tab-panel {
    padding: 0;
  }

  .c-panel {
    overflow-x: hidden !important;
    overscroll-behavior: auto;

    &,
    &:first-child,
    &:last-child {
      padding: settings.$spacing-medium;
    }
  }

  .react-tabs__tab-panel.react-tabs__tab-panel--selected {
    position: relative;

    &::after {
      border-left: 1px solid settings.$color-neutral;
      content: '';
      position: absolute;
      top: auto;
      bottom: 0;
      left: 100%;
      transform: none;
      overflow: hidden;
      height: calc(100% - #{settings.$spacing-medium});

      @include mq.mq($from: medium) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

// Added 1st time in `pages/user/programs/ProgramExercises.js
.p-tabs--tab-panel {
  .c-list__item-clickable.c-list__item-active:hover
    .c-list__item-wrapping-note-preview
    p {
    opacity: 1;
  }

  .c-list__item-clickable.c-list__item-active {
    .c-list__item-small-editing-area {
      display: flex;
    }

    .c-list__item-small-editing-area .c-button__group {
      display: none;
    }
  }
}

.p-tabs__active-tab-content-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: settings.$spacing-small;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  container: tabs-container / size;
  height: 500px;

  > .c-list {
    padding: settings.$spacing-small settings.$spacing-medium 0;
    margin-left: -#{settings.$spacing-medium};
    margin-right: -#{settings.$spacing-medium};
  }

  > .r-list__header--sticky + .c-list {
    padding: (settings.$spacing-small * 1.5) settings.$spacing-medium 0;
  }

  > form:not([class]),
  > form[class=''],
  > .c-card--multiple-body,
  > .c-card,
  > .c-panel__tools {
    margin-top: settings.$spacing-small;
    // margin-bottom: settings.$spacing-small;

    + .c-list {
      padding-top: 0;
    }
  }

  .c-card__body--tools {
    padding: 0;
  }
}

.p-tabs__with-header {
  > .c-breadcrumbs-wrapper--for-lists {
    padding-bottom: 0;
    padding-top: 12px;
  }

  > .c-input__group {
    margin: 12px -16px 0 -16px;
    padding: 0 12px 12px;
    box-shadow: settings.$shadow-bottom;
    z-index: 1;
    flex: 0;
  }
}

.p-tabs__with-content-and-cards {
  // Check #161
  padding: 24px !important;
  margin: 0 !important;

  .c-card--secondary {
    margin-top: 12px;
    background: settings.$color-neutral-x-light;
    padding: 16px 20px 24px;
  }
}

.p-tabs__tab-header {
  margin: (settings.$spacing-small * 1.5) (-#{settings.$spacing-medium}) 0
    (-#{settings.$spacing-medium});
  padding: 0 settings.$spacing-medium (settings.$spacing-small * 1.5);
  box-shadow: settings.$shadow-bottom;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 8px;

  .c-modal__content & {
    margin-left: -8px;
    margin-right: -8px;
    padding: 0 (settings.$spacing-medium * 1.5) (settings.$spacing-small * 1.5);
  }

  .c-modal__content &:has(+ .c-list__filtering-message),
  .c-modal__content &:has(+ .r-list__header) {
    padding: 0 (settings.$spacing-medium * 1.5) 0;
  }

  &:has(+ .c-list__filtering-message),
  &:has(+ .r-list__header) {
    box-shadow: none;
    padding-bottom: 0;
  }

  ~ .c-list {
    padding-top: 12px;
    outline-offset: -2px;
  }
}

.p-tabs__tab-header--element {
  width: calc(50% - 4px);
  flex: auto;
}

.p-tabs__tab-header-and-list-header {
  box-shadow: none;
  padding-bottom: settings.$spacing-mini;

  > .c-input__group {
    padding: 0;
    margin-bottom: 0;
  }

  + .c-list {
    padding: 0 16px 0;

    .c-list__header {
      top: 0 !important;
      height: auto;
    }

    .c-list__header-sticky {
      padding: 0;
      margin-bottom: 12px;
      z-index: 102;
      height: 32px;
    }

    .c-list__header-labels {
      box-shadow: settings.$shadow-bottom;
      padding: 8px 16px;
    }
  }
}

.p-tabs__tab-header-no-shadow {
  margin: 8px -16px 0 -16px;
  box-shadow: none;

  & ~ .r-list__header--sticky {
    padding: 0px 16px 4px;
    margin-top: -4px;
  }
}

.p-tabs__active-tab-content-wrapper--scroll {
  .c-list {
    overflow: visible;
  }

  @include mq.mq($from: small) {
    overflow-y: scroll;
  }
}

.p-tabs-head {
  padding-top: settings.$spacing-small;
  padding-bottom: settings.$spacing-medium;
  background-color: settings.$color-neutral-x-light;
  box-shadow: 0 4px 4px -7px settings.$color-neutral-x-dark;
}

.p-tabs-head--sticky {
  position: sticky;
  top: 0;
  z-index: 28;

  @container tabs-container (max-height: 487px) {
    position: static;

    &:has(+ .c-card--edit-test-result .c-input-select__menu) {
      z-index: 1;
    }
  }
}
