@use '_scss/settings';

.c-icon {
  width: settings.$small-icon;
  height: settings.$small-icon;
  background-color: settings.$transparent;
  pointer-events: none;

  use {
    fill: settings.$color-neutral-dark;
  }
}

.c-icon--input {
  position: absolute;
  width: settings.$small-icon;
  height: settings.$small-icon;
  right: settings.$spacing-small + settings.$spacing-tiny;
  top: settings.$spacing-small;
  pointer-events: none;
}

.c-icon--tiny {
  width: 12px;
  height: 12px;
}

.c-icon--small {
  width: settings.$small-icon;
  height: settings.$small-icon;
}

.c-icon--small-medium {
  width: settings.$small-medium-icon;
  height: settings.$small-medium-icon;
}

.c-icon--medium {
  width: settings.$medium-icon;
  height: settings.$medium-icon;
}

.c-icon--huge {
  width: settings.$huge-icon;
  height: settings.$huge-icon;
}

.c-icon--x-huge {
  width: settings.$x-huge-icon;
  height: settings.$x-huge-icon;
}

// CHECK THIS
@each $current-color-name, $current-color in settings.$colors-list {
  .c-icon--fill-#{$current-color-name} {
    use {
      fill: $current-color !important;
    }
  }

  .c-icon--bg-#{$current-color-name} {
    background-color: $current-color;
  }

  .c-icon--stroke-#{$current-color-name} {
    stroke: $current-color;
    color: $current-color;

    use {
      stroke: inherit !important;
    }
  }

  .c-icon--inherit-#{$current-color-name} {
    color: $current-color;
  }
}

.c-icon--rotated-90 {
  transform: rotate(-90deg);
}

.c-icon--mirrored {
  transform: rotate(180deg);
}
