@use '_scss/settings';

.c-graphic {
  width: 100%;
  height: 20px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-graphic__area {
  position: relative;
  height: 100%;
  border-radius: 4px;
  border: 1px solid settings.$color-neutral-x-light;
}

.c-graphic__area-label {
  position: absolute;

  @include settings.font-size(10px);
}

.c-graphic__position {
  position: absolute;

  background-color: settings.$color-primary-dark;
  transform: translateX(-50%);
}

.c-graphic--maturity {
  margin-bottom: settings.$spacing-medium * 1.5;
  margin-top: settings.$spacing-medium * 1.5;
  outline: 1px solid settings.$color-neutral-x-light;
  border-radius: 4px;
  background: settings.$color-neutral-x-light;

  .c-graphic__area {
    width: calc(100% / 3);
    background-color: settings.$color-growth-red;
  }

  .c-graphic__area-label {
    bottom: -#{settings.$spacing-small * 2.5};
  }

  .c-graphic__lt-area {
    background-color: settings.$color-growth-lime;
  }

  .c-graphic__gt-area {
    background-color: settings.$color-growth-green;
  }

  .c-graphic__lt-area-label {
    right: -10.5px;
  }

  .c-graphic__middle-area-label {
    left: 50%;
    transform: translateX(-50%);
  }

  .c-graphic__gt-area-label {
    left: -11.5px;
  }
}

.c-graphic--pah {
  margin-bottom: 24px;
  margin-top: 24px;

  outline: 1px solid settings.$color-neutral-x-light;
  border-radius: 4px;
  background: settings.$color-neutral-x-light;

  .c-graphic__area {
    border-radius: 4px;
    border: 1px solid settings.$color-neutral-x-light;
  }

  .c-graphic__area-label {
    bottom: -20px;
    color: settings.$color-primary-dark;
    font-family: settings.$font-family-light;

    &::before {
      content: none;
    }
  }

  .c-graphic__lt-area {
    width: calc(calc(6 / 20) * 100%);
    background-color: settings.$color-growth-lime;
  }

  .c-graphic__lt-area-label {
    right: -11px;
  }

  .c-graphic__middle-lt-area {
    width: calc(calc(3 / 20) * 100%);
    background-color: settings.$color-progress-orange-light;
  }

  .c-graphic__middle-lt-area-label {
    right: -11px;
  }

  .c-graphic__middle-gt-area {
    width: calc(calc(6 / 20) * 100%);
    background-color: settings.$color-growth-red;
  }
  .c-graphic__middle-gt-area-label {
    right: -10px;
  }

  .c-graphic__gt-area {
    width: calc(calc(5 / 20) * 100%);
    background-color: settings.$color-growth-green;
  }

  .c-graphic__gt-area-label {
    left: auto;
    right: -2px;

    &::before {
      left: auto;
      right: 6px;
    }
  }
}

.c-graphic__position {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  width: 2px;
  height: 14px;
  top: 1px;
  outline: 2px solid settings.$color-neutral-x-light;

  &::before {
    content: attr(data-value);
    position: absolute;
    top: -18px;
    left: -50%;
    transform: translateX(-50%);
    color: currentColor;
    white-space: nowrap;
    font-family: settings.$font-family-bold;
  }

  .c-table-cell--even-half & {
    @include settings.font-size(11px);
  }

  .c-graphic--value-right & {
    &::before {
      left: auto;
      transform: none;
      right: 0;
    }
  }

  .c-graphic--value-left & {
    &::before {
      right: auto;
      transform: none;
      left: 0;
    }
  }

  .c-graphic--maturity.c-graphic--value-left & {
    &::before {
      left: -3px;
    }
  }
}
