@use '_scss/settings';
@use '_scss/07-plugins/mq' with (
  $breakpoints: settings.$mq-breakpoints
);

.c-menu {
  display: none;
  border-radius: settings.$tiny-radius;
  box-shadow: settings.$menu-box-shadow;
  min-width: 200px;
  width: 240px;
  margin-top: settings.$spacing-small;
  background: settings.$color-neutral-x-light;
}

.c-menu--open {
  display: block;
  z-index: 10;
  margin-bottom: settings.$spacing-medium;
  overflow: auto;
  max-height: 150px;
  min-width: 260px;

  .c-panel__header &,
  .c-page__header &,
  .r-panel__header & {
    max-height: calc(100dvh - 216px);
  }
}

.c-menu__wrapper {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  .c-menu--open {
    position: absolute;
    right: 0;
    top: 100%;
  }

  .c-button--breadcrumb + .c-menu--open {
    right: auto;
    left: 0;
  }

  .c-panel__title .c-badge + & {
    margin-left: 6px;
    align-self: flex-start;
  }
}

.c-menu__wrapper--expandable {
  width: 32px;
  margin-right: 0;
}

.c-menu__wrapper--as-modal {
  max-width: none;

  .c-button {
    z-index: 100;
  }

  .c-menu {
    width: auto;
    top: calc(100% + 8px);
    margin: 0;

    .c-menu__body {
      padding: 0;
    }

    .c-card--helper {
      margin: inherit;
    }

    .c-card__body--benchmarks {
      margin: inherit;
    }
  }

  &[aria-expanded='true'] + .c-menu__wrapper-overlay {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9;
  }
}

.c-menu__wrapper-helper {
  &.c-menu__wrapper:first-child:last-child {
    margin-left: settings.$spacing-small;
  }

  & + .c-menu__wrapper-overlay {
    margin-left: 0 !important;
  }

  .c-button {
    z-index: 2;
  }

  &[aria-expanded='true'] + .c-menu__wrapper-overlay {
    z-index: 25;
  }

  .c-menu--open {
    z-index: 26;
  }

  &.u-margin-left-small:nth-last-child(2):first-child {
    margin-left: auto !important;
  }
}

.c-menu__wrapper-helper-wide {
  .c-menu--open {
    max-height: 45vh;
  }

  .c-card--helper {
    max-width: 440px;
  }

  .c-card__body {
    padding: settings.$spacing-large (settings.$spacing-small * 5)
      (settings.$spacing-medium * 1.5);
  }

  @include settings.font-size(13px);

  .c-card__body h2 {
    font-family: settings.$font-family-bold;
    color: settings.$color-primary-dark;
    @include settings.font-size(24px, 1.3);
  }

  .c-card__body p {
    font-family: settings.$font-family-light;
  }

  .c-card__body ol {
    font-family: settings.$font-family-regular;
    margin: 0;
    counter-reset: counter-ol;
    list-style: none;
  }

  .c-card__body ol > li {
    margin-bottom: settings.$spacing-small;
    counter-increment: counter-ol;
    display: flex;

    &::before {
      content: counter(counter-ol);
      background: settings.$color-neutral-x-light;
      width: settings.$spacing-medium * 1.5;
      height: settings.$spacing-medium * 1.5;
      border-radius: 50%;
      display: inline-block;
      line-height: settings.$spacing-medium * 1.5;
      color: settings.$color-neutral-dark;
      text-align: center;
      flex: 0 0 settings.$spacing-medium * 1.5;
      margin: -3px settings.$spacing-small 0 0;

      @include settings.font-size(12px, settings.$spacing-medium * 1.5);
    }
  }
}

.c-menu__wrapper--organisation {
  position: static;

  &[aria-expanded='true'] + .c-menu__wrapper-overlay {
    height: calc(100dvh - 72px);
    top: 72px;

    @include mq.mq($until: small) {
      height: calc(100dvh - 38px);
      top: 38px;
    }
  }

  .c-menu--open-organisations {
    box-shadow: 0 5px 7px -3px rgba(7, 22, 73, 0.5);
    z-index: 100;
    border-radius: 0;
    top: 72px;
    left: 0;
    overflow: auto;
    height: 400px;
    width: calc(100vw - 72px);

    max-height: calc(100dvh - 72px);
    position: absolute;
    padding-bottom: settings.$spacing-small;

    &::after {
      content: '';
      left: 0;
      right: 0;
      height: settings.$spacing-medium + settings.$spacing-small;
      position: absolute;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0.1%,
        rgba(255, 255, 255, 0.9) 60%,
        rgb(255, 255, 255) 100%
      );
      z-index: 100;
    }

    .c-menu__body {
      max-width: 566px;
      margin: auto;
      position: relative;
      overflow: auto;
      max-height: 100%;
      height: auto;

      min-height: 100%;
      overflow-y: scroll;
    }

    @include mq.mq($until: small) {
      top: 38px;
      width: 100%;
    }
  }
}

.u-growth-left,
.c-menu__wrapper--select {
  max-width: none;
  width: 100%;

  .c-menu--open {
    width: calc(100% - 72px);
    right: auto;
    left: 0;
  }
}

.c-menu__loader-paragraph {
  text-align: center;
  margin: 0;
  font-family: settings.$font-family-light;
  text-transform: none;
}

.c-menu__header {
  border-bottom: 1px solid settings.$color-neutral;
  padding: settings.$spacing-medium;
}

.c-menu__title {
  margin-bottom: 0;
  font-family: settings.$font-family-bold;
  color: settings.$color-primary-dark;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  @include settings.font-size(16px, 1.2);
}

.c-menu__subtitle {
  margin-bottom: 0;
  font-family: settings.$font-family-regular;
  color: settings.$color-neutral-dark;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  @include settings.font-size(13px);
}

.c-menu__body {
  padding: settings.$spacing-small 0;
}

.c-menu__link,
.c-menu__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: default;

  text-decoration: none;
  text-align: left;
  font-family: settings.$font-family-regular;
  color: settings.$color-neutral-dark;
  padding: settings.$spacing-small settings.$spacing-medium;
  padding-left: settings.$spacing-large + settings.$spacing-small;

  @include settings.font-size(13px, 1.3);

  &:hover,
  &:active,
  &:focus {
    background-color: settings.$color-primary-10; // == button--tertiary
  }

  .c-icon {
    position: absolute;
    left: settings.$spacing-medium;
    top: settings.$spacing-small;
    width: settings.$small-icon;
    height: settings.$small-icon;
  }
}

.c-menu--select {
  max-width: none;
  width: 100%;
}

.c-menu-group {
  display: flex;
  flex-direction: column;
  margin: 0;
  border-bottom: 1px solid settings.$color-neutral;
  padding-bottom: 8px;
  margin-bottom: 8px;

  .c-button.is-active {
    color: settings.$color-primary-dark;
  }

  &:hover {
    .c-menu-group__item {
      display: block !important;
    }
  }

  &:first-child:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}
