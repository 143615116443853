@use '_scss/settings';
@use 'sass:color';
/* ==========================================================================
   #THEME Colors
   ========================================================================== */

/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-color-primary {}
 *   .u-bgcolor-secondary-light {}
 */
// Makes a color lighter
@function tint($color, $percent) {
  @return color.mix(#fff, $color, $percent);
}

// Makes a color darker
@function shade($color, $percent) {
  @return color.mix(#000, $color, $percent);
}

$color-property: (
  'color': 'color',
  'bgcolor': 'background-color'
);

$colors-list: (
  '-neutral': settings.$color-neutral,
  '-primary': settings.$color-primary,
  '-secondary': settings.$color-secondary,
  '-tertiary': settings.$color-tertiary,
  '-success': #2ecc40,
  '-error': #ff4136,
  '-notice': #ffdc00,
  '-growth-red': #f3726e,
  '-growth-orange': #ff9800,
  '-growth-yellow': #feea3d,
  '-growth-lime': #7cda59,
  '-growth-green': #00c28f
);

$variations: (
  null: null,
  '-xlight': '-xlight',
  '-light': '-light',
  '-dark': '-dark'
);

@each $property-namespace, $property in $color-property {
  @each $color-namespace, $colors-descriptions in $colors-list {
    @each $variation-namespace, $variation in $variations {
      .u-#{$property-namespace}#{$color-namespace}#{$variation-namespace} {
        @if $variation == '-xlight' {
          @if $color-namespace == '-neutral' {
            #{$property}: tint($colors-descriptions, 90%) !important;
          } @else if $color-namespace == '-primary' {
            #{$property}: tint($colors-descriptions, 45%) !important;
          } @else {
            #{$property}: tint($colors-descriptions, 30%) !important;
          }
        } @else if $variation == '-light' {
          @if $color-namespace == '-neutral' {
            #{$property}: tint($colors-descriptions, 85%) !important;
          } @else if $color-namespace == '-primary' {
            #{$property}: tint($colors-descriptions, 25%) !important;
          } @else {
            #{$property}: tint($colors-descriptions, 10%) !important;
          }
        } @else if $variation == '-dark' {
          @if $color-namespace == '-primary' {
            #{$property}: shade($colors-descriptions, 20%) !important;
          } @else {
            #{$property}: shade($colors-descriptions, 10%) !important;
          }
        } @else {
          #{$property}: #{$colors-descriptions} !important;
        }
      }
    }
  }
}
