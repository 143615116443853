// SETTINGS
@use 'sass:map';
@use 'sass:math';
@use 'sass:meta';
@use 'sass:color';

///* ========================================================================
//   #CORE: mostly proportions & vertical rhythm
//   ======================================================================== */

// This core file sets up inuitcss’ most important setup variables. They
// underpin a lot of how the framework functions and should be modified and
// preconfigured with caution.

// Baseline grid lines height.
// Every spacing metric should be based on this.

$inuit-global-baseline: 4px !default;

// How many grid lines should our spacing unit variants span?
// Each value should be an unitless integer.

$inuit-global-spacing-unit-factor-tiny: 1 !default; // 6px
$inuit-global-spacing-unit-factor-small: 2 !default; // 12px
$inuit-global-spacing-unit-factor-medium: 4 !default; // 24px
$inuit-global-spacing-unit-factor-large: 8 !default; // 48px
$inuit-global-spacing-unit-factor-huge: 16 !default; // 96px

// Spacing values are determined based on your project’s global baseline grid.
// It is not recommended that you modify these following variables
// (it can break your vertical rhythm), but if you need to, you can.

$inuit-global-spacing-unit-tiny: $inuit-global-baseline *
  $inuit-global-spacing-unit-factor-tiny !default; // 6 * 1 = 6
$inuit-global-spacing-unit-small: $inuit-global-baseline *
  $inuit-global-spacing-unit-factor-small !default; // 6 * 2 = 12
$inuit-global-spacing-unit-medium: $inuit-global-baseline *
  $inuit-global-spacing-unit-factor-medium !default; // 6 * 4 = 24
$inuit-global-spacing-unit-large: $inuit-global-baseline *
  $inuit-global-spacing-unit-factor-large !default; // 6 * 8 = 48
$inuit-global-spacing-unit-huge: $inuit-global-baseline *
  $inuit-global-spacing-unit-factor-huge !default; // 6 * 16 = 96

// Base typographical styles.

$inuit-global-font-size: 13px !default;
$inuit-global-line-height: $inuit-global-spacing-unit-medium !default; // 24px

// Check that the chosen font rules are pixel numbers.

@each $_inuit-font-globals in $inuit-global-font-size $inuit-global-line-height
{
  @if (meta.type-of($_inuit-font-globals) == number) {
    @if (math.unit($_inuit-font-globals) != 'px') {
      @error "`#{$_inuit-font-globals}` needs to be a pixel value.";
    }
  } @else {
    @error "`#{$_inuit-font-globals}` needs to be a number.";
  }
}

// Check that the chosen size factors are unitless, integer numbers.

@each $_inuit-spacing-unit
  in $inuit-global-spacing-unit-factor-tiny
  $inuit-global-spacing-unit-factor-small
  $inuit-global-spacing-unit-factor-medium
  $inuit-global-spacing-unit-factor-large
  $inuit-global-spacing-unit-factor-huge
{
  @if (meta.type-of($_inuit-spacing-unit) == number) {
    @if (math.is-unitless($_inuit-spacing-unit) == false) {
      @error "`#{$_inuit-spacing-unit}` needs to be unitless.";
    }

    @if ($_inuit-spacing-unit != math.ceil($_inuit-spacing-unit)) {
      @error "`#{$_inuit-spacing-unit}` needs to be an integer.";
    }
  } @else {
    @error "`#{$_inuit-spacing-unit}` needs to be a number.";
  }
}

///* ========================================================================
//   #COLORS
//   ======================================================================== */

// Tint & shade helper functions (http://bourbon.io/docs/#tint-shade)

// Makes a color lighter
@function tint($color, $percent) {
  @return color.mix(#fff, $color, $percent);
}

// Makes a color darker
@function shade($color, $percent) {
  @return color.mix(#000, $color, $percent);
}

$transparent: rgba(0, 0, 0, 0);

$dodger-blue: #3c84f0;
$night-blue: #071649;
$package-blue: #008dfa;
$dodger-blue-light: rgb(225, 233, 247); //!important

$green-blue: #00c28f;
$red: #ff3333;
$orange: #ff9800;

$white: #fff;
$darker-white: #f9fafb;
$pale-grey: #f4f5f7;
$silver: #d6dadc;
$steel: #7a8198;
$charcoalgrey: #34373e;
$black: #000;

// Brand colors
$color-neutral-x-light: $white;
$color-neutral-lighter: $darker-white;
$color-neutral-light: $pale-grey;
$color-neutral: $silver;
$color-neutral-dark: $steel;
$color-neutral-x-dark: $charcoalgrey;
$color-neutral-xx-dark: $black;

$color-primary: $dodger-blue;
$color-primary-10: rgba($dodger-blue, 0.1); // rgb(225, 233, 247)
$color-primary-25: rgba($dodger-blue, 0.25);
$color-primary-light: $dodger-blue-light;

$color-primary-dark: $night-blue;
$color-primary-dark-10: rgba($night-blue, 0.1);
$color-primary-dark-15: rgba($night-blue, 0.15);
$color-primary-dark-25: rgba($night-blue, 0.25);
$color-primary-dark-50: rgba($night-blue, 0.5);

$color-secondary: $orange;
$color-tertiary: $package-blue;
// UI colors
$color-success: $green-blue;
$color-success-25: rgba($green-blue, 0.25);
$color-error: $red;
$color-error-25: rgba($red, 0.25);
$color-notice: $orange;
$color-notice-25: rgba($orange, 0.25);

$color-progress-red: #ed2720;
$color-progress-orange: #ff8f00;
$color-progress-orange-light: #ffc107;
$color-progress-yellow: #feea3d;
$color-progress-lime: #b7e500;
$color-progress-green: #35c600;
$color-progress-average: rgba($night-blue, 0.1);

$color-growth-red: #f3726e;
$color-growth-orange: #ff9800;
$color-growth-yellow: #feea3d;
$color-growth-lime: #7cda59;
$color-growth-green: #00c28f;

$color-spvla-dark-blue: #006fb7;
$color-spvla-light-blue: #38b3e8;
$color-spvla-light-beige: #f6f5ed;
$color-spvla-light-gray: #363635;

$color-gradient-x-light-to-transparent: linear-gradient(
  to right,
  $color-neutral-x-light 94%,
  rgba($color-neutral-x-light, 0.7) 96.5%,
  $transparent 100%
);

$color-gradient-lighter-to-transparent: linear-gradient(
  to right,
  $color-neutral-lighter 94%,
  rgba($color-neutral-lighter, 0.7) 96.5%,
  $transparent 100%
);

$colors-list: (
  'transparent': rgba(0, 0, 0, 0),
  'color-neutral-x-light': #fff,
  'color-neutral-light': #f4f5f7,
  'color-neutral': #d6dadc,
  'color-neutral-dark': #7a8198,
  'color-neutral-x-dark': #34373e,
  'color-neutral-xx-dark': #000,
  'color-primary': #3c84f0,
  'color-primary-10': rgba(#3c84f0, 0.1),
  'color-primary-25': rgba(#3c84f0, 0.25),
  'color-primary-dark': #071649,
  'color-primary-dark-10': rgba(#071649, 0.1),
  'color-primary-dark-15': rgba(#071649, 0.15),
  'color-primary-dark-50': rgba(#071649, 0.5),
  'color-success': #00c28f,
  'color-success-25': rgba(#00c28f, 0.25),
  'color-error': #f33,
  'color-error-25': rgba(#f33, 0.25),
  'color-notice': #ff9800,
  'color-notice-25': rgba(#ff9800, 0.25),
  'color-growth-red': #f3726e,
  'color-growth-orange': #ff9800,
  'color-growth-yellow': #feea3d,
  'color-growth-lime': #7cda59,
  'color-growth-green': #00c28f
);

// Syntax colors (default is solarized)
$color-syntax-background: #111111; // body text / default code / primary
$color-syntax-primary: #93a1a1; // body text / default code / primary
$color-syntax-secondary: #586e75; // comments / secondary content
$color-syntax-tertiary: #cb4b16; // constants
$color-syntax-special: #dc322f; // regex, special keywords
$color-syntax-keywords: #268bd2; // reserved keywords
$color-syntax-strings: #2aa198; // strings, numbers
$color-syntax-operators: #859900; // operators, other keywords

///* ========================================================================
//   #CONFIG
//   ======================================================================== */

// A map of global config settings. Define any project-level configuration,
// feature switches, etc. in here.

// $inuit-config: (
//   env: dev,
//   healthcheck: false,
//   debug: true
// );

// You can access data in this map using the following function:
//
// inuit-config(<key>)
//
// Example usage:
//
// @if (inuit-config(debug) == true) { ...  }

// @function inuit-config($key) {
//   @return map.get($inuit-config, $key);
// }

///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// $fontface: 'Lato', sans-serif;
$font-family-regular: 'lato', sans-serif;
$font-family-light: 'latolight', sans-serif;
$font-family-bold: 'latoblack', sans-serif;

$font-default-details-color: $color-primary-dark;
$font-status-label-color: $color-neutral-dark;
$font-hint-color: rgba($color-neutral-dark, 0.5);
$font-error-color: $color-error;

$font-default-size: 13px;
$font-small-size: 10px;

// Alias
$spacing-mini: math.div($inuit-global-spacing-unit-tiny, 2); // 2
$spacing-tiny: $inuit-global-spacing-unit-tiny; // 4
$spacing-small: $inuit-global-spacing-unit-small; // 8
$spacing-medium: $inuit-global-spacing-unit-medium; // 16
$spacing-large: $inuit-global-spacing-unit-large; // 32
$spacing-huge: $inuit-global-spacing-unit-huge; // 64

// Standardise some UI treatments.
$huge-radius: $spacing-huge; //64
$large-radius: $spacing-large; // 32
$medium-radius: $spacing-medium; // 16
$small-medium-radius: $spacing-tiny + $spacing-small; // 12
$small-radius: $spacing-small; // 8
$tiny-radius: $spacing-tiny; // 4
$circular-radius: 50%;

// Icon Sizes:
$x-huge-icon: 96px;
$huge-icon: 40px;
$large-icon: 32px;
$medium-icon: 24px;
$small-medium-icon: 20px;
$small-icon: 16px;

// $text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
$list-box-shadow: 0 0 2px 0 rgba($color-primary-dark, 0.1);
$menu-box-shadow: 0 2px 8px 0 rgba(7, 22, 73, 0.5);
$dragging-shadow: 0 0 10px -7px $color-neutral-x-dark;
$body-modal-shadow-top: inset 0 5px 6px -10px $color-neutral-x-dark;
$shadow-top: 0 -6px 6px -10px $color-neutral-x-dark;
$shadow-bottom: 0 6px 6px -10px $color-neutral-x-dark;

$global-border-width-tiny: 1px;
// $global-border-width-small: 4px;
// $global-border-width-medium: 10px;
// $global-border-width-large: 15px;

$global-transition: all 300ms ease-in-out !default;
$global-transition-fast: all 150ms ease-in-out !default;

// Breakpoints (https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862#.necmuodfd)
$mq-breakpoints: (
  tiny: 470px,
  small: 600px,
  medium: 900px,
  large: 1200px,
  xlarge: 1400px,
  huge: 1800px
);

// $mq-show-breakpoints: (tiny, small, medium, large, xlarge, huge);

///* ========================================================================
//   _objects.ratio:
//   ========================================================================
//   $inuit-ratios: (
//     "2\\:1"         : (2:1),
//     "4-by-3"        : (4:3),
//     "full-hd"       : (16:9),
//     "card-image"    : (2:3),
//     "golden-ratio"  : (1.618:1) -> non-integers are okay
//   ) !default;
// class="u-2-by-1"
// http://alistapart.com/article/creating-intrinsic-ratios-for-video
// 1. Default is a 1:1 ratio (i.e. a perfect square).

$inuit-ratios: (
  '2-by-1': (
    2: 1
  ),
  '4-by-3': (
    4: 3
  ),
  '16-by-9': (
    16: 9
  ),
  'rating': (
    114: 139
  ),
  '145': (
    110: 160
  ),
  '164': (
    110: 180
  )
) !default;

///* ========================================================================
//   _objects.responsive-wrapper:
//   ========================================================================

$inuit-wrapper-width-tiny: 400px;
$inuit-wrapper-width-small: 600px;
$inuit-wrapper-width-medium: 900px;
$inuit-wrapper-width-large: 1200px;
$inuit-wrapper-width-huge: 1800px;
$inuit-wrapper-breakpoint: large;

///* ========================================================================
//   _utilities.responsive-spacings:
//   ========================================================================

// /**
// * This would bring us the following classes:
//  *
//  *   .u-margin-small-at-small {}
//  *   .u-margin-small-at-medium {}
//  *   .u-margin-small-at-large {}
//  *   .u-margin-small-at-huge {}
//  *   .u-margin-bottom-small-at-small {}
//  *   .u-margin-bottom-small-at-medium {}
//  *   .u-margin-bottom-small-at-large {}
//  *   .u-margin-bottom-small-at-huge {}
//  *
//  *   Not active by default!//
//  */

///* ========================================================================
//   _utilities.spacings:
//   ========================================================================

// .u-margin-top-medium, .u-padding-left-large, .u-padding-medium, .u-padding-right-none, .u-padding-horizontal-medium, enz...
$inuit-spacing-sizes: (
  '-tiny': $inuit-global-spacing-unit-tiny,
  '-small': $inuit-global-spacing-unit-small,
  '-medium': $inuit-global-spacing-unit-medium,
  '-large': $inuit-global-spacing-unit-large,
  '-huge': $inuit-global-spacing-unit-huge,
  '-none': 0,
  '-auto': auto
) !default;

// TOOLS
///* ========================================================================
//   #FONT-SIZE
//   ======================================================================== */

// Generates a rem font-size (with pixel fallback) and a baseline-compatible
// unitless line-height from a pixel font-size value. Basic usage is simply:
//
//   @include font-size(18px);
//
// You can force a specific line-height by passing it as the second argument:
//
//   @include font-size(16px, 1);
//
// You can also modify the line-height by increments, while staying in the
// baseline grid, by setting the `$modifier` parameter. It takes a positive
// or negative integer, and it will add or remove "lines" to the  generated
// line-height. This is the recomended way to do it, unless you really need
// an absolute value. i.e.:
//
//   // add 2 lines:
//   @include font-size(24px, $modifier: +2);
//
//   // subtract 1 line:
//   @include font-size(24px, $modifier: -1);

@mixin font-size(
  $font-size,
  $line-height: auto,
  $modifier: 0,
  $important: false
) {
  @if (meta.type-of($font-size) == number) {
    @if (math.unit($font-size) != 'px') {
      @error "`#{$font-size}` needs to be a pixel value.";
    }
  } @else {
    @error "`#{$font-size}` needs to be a number.";
  }

  @if ($important == true) {
    $important: !important;
  } @else if ($important == false) {
    $important: null;
  } @else {
    @error "`#{$important}` needs to be `true` or `false`.";
  }

  // We provide a `px` fallback for old IEs not supporting `rem` values.
  //   font-size: $font-size $important;
  font-size: math.div($font-size, $inuit-global-font-size) * 1rem $important;

  @if ($line-height == 'auto') {
    // Define how many grid lines each text line should span.
    // By default, we set it to the minimum number of lines necessary
    // in order to contain the defined font-size, +1 for some breathing room.
    // This can be modified with the `$modifier` parameter.
    $lines: math.ceil(math.div($font-size, $inuit-global-baseline)) +
      $modifier +
      1;
    $line-height: $lines * $inuit-global-baseline;

    line-height: math.div($line-height, $font-size) $important;
  } @else {
    @if (
      meta.type-of($line-height) ==
        number or
        $line-height ==
        'inherit' or
        $line-height ==
        'normal'
    ) {
      line-height: $line-height $important;
    } @else if ($line-height != 'none' and $line-height != false) {
      @error "D’oh! `#{$line-height}` is not a valid value for `$line-height`.";
    }
  }
}

///* ========================================================================
//   #HIDDEN-VISUALLY
//   ======================================================================== */

// Mixin to quickly apply accessible hiding to elements.

@mixin inuit-hidden-visually() {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}
