@use '_scss/settings';

.c-badge {
  display: inline-block;
  background-color: settings.$color-primary-10;
  flex: 0 0 auto;

  border-radius: settings.$small-medium-radius;
  padding: 0 settings.$spacing-small;
  margin-right: settings.$spacing-tiny;

  text-align: center;
  font-family: settings.$font-family-bold;
  color: settings.$color-primary;

  white-space: nowrap;
  overflow: hidden;

  @include settings.font-size(10px, 2);

  .c-list__item & {
    margin: -#{settings.$spacing-tiny} settings.$spacing-small;
  }

  .c-panel__title & {
    flex: 0 0 auto;
    margin-top: 6px;
    align-self: flex-start;
  }
}

.c-badge--notification {
  border-radius: settings.$medium-radius;
  border: 1px solid transparent;
  padding: settings.$spacing-tiny settings.$spacing-medium;

  display: inline-flex;
  align-items: center;
  text-transform: none;
  font-weight: normal;

  font-family: settings.$font-family-regular;
  font-weight: normal;
  color: settings.$color-neutral-x-light;
  background-color: settings.$color-success;
  opacity: 0;
  z-index: 102;

  position: absolute;
  top: -#{settings.$spacing-large};
  left: 50%;
  transform: translateX(-50%);

  transition:
    top 0.3s ease-in-out,
    opacity 0.1s ease-in-out;
  @include settings.font-size(settings.$inuit-global-font-size, 1.7);

  &.c-badge--on-context {
    transform: none;
    right: auto;
    top: auto;
  }

  &.c-badge--closeable {
    padding-right: 40px;
    box-shadow: settings.$menu-box-shadow;

    .c-button {
      position: absolute;
      right: 12px;
      top: 6px;
    }
  }
}

.c-badge--error {
  background-color: settings.$color-error;
}

.c-badge--show {
  top: settings.$spacing-large;
  opacity: 1;
}

.c-badge--neutral {
  background-color: settings.$color-neutral-light;
  color: settings.$color-neutral-dark;
  border: 1px solid settings.$color-neutral;
}

.c-badge--dark {
  padding: settings.$spacing-mini (settings.$spacing-small * 1.5)
    settings.$spacing-mini (settings.$spacing-small * 1.5);

  background-color: settings.$color-neutral-dark;
  color: settings.$color-neutral-x-light;

  font-family: settings.$font-family-regular;
  @include settings.font-size(settings.$inuit-global-font-size, 1.5);
}

.c-badge--large {
  min-width: settings.$spacing-medium * 1.5;
  height: settings.$spacing-medium * 1.5;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  @include settings.font-size(16px, 1.5);
}

.c-badge--light {
  font-family: settings.$font-family-regular;
}
