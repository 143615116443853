@use '_scss/settings';
@use '_scss/07-plugins/mq' with (
  $breakpoints: settings.$mq-breakpoints
);

.c-card {
  position: relative;
  margin: 0 auto settings.$spacing-medium;
  border-radius: settings.$small-radius;
  width: 90%;
  max-width: none;

  @include mq.mq($from: small) {
    width: settings.$inuit-wrapper-width-small + 100px;
    max-width: 90%;
  }
}

.c-card--shadow {
  box-shadow: 0 0 15px -7px rgba(settings.$color-primary-dark, 0.5);
}

.c-card--primary {
  width: 90%;
  max-width: none;
  overflow: hidden;

  @include mq.mq($from: small) {
    width: 520px;
    max-width: none;
  }

  .c-button__group {
    width: 100%;
    justify-content: center;
  }
}

.c-card--login {
  .c-logo-img {
    width: 120px;
    // margin-left: 0;
    // margin-bottom: auto;
  }

  .c-card__header--primary {
    padding: settings.$spacing-large settings.$spacing-huge;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .c-card__header-title,
  .c-card__header-subtitle {
    width: 100%;
    margin: auto;
  }

  .c-card__header--primary {
    padding: 24px 16px;
    text-align: center;
  }

  .c-logo-img {
    margin: 0 auto 16px;
    margin-bottom: 16px;
    margin-left: auto;
    position: static;
    width: 90px;
  }

  @include mq.mq($from: tiny) {
    .c-logo-img {
      position: absolute;
      right: settings.$spacing-medium;
      margin-bottom: 0;
    }

    .c-card__header--primary {
      padding: 24px 16px;
      text-align: left;
    }

    .c-card__header-title,
    .c-card__header-subtitle {
      margin: 0;
      width: calc(100% - 152px);
    }
  }

  @include mq.mq($from: small) {
    .c-card__header--primary {
      padding: 24px 64px;
    }

    .c-logo-img {
      right: settings.$spacing-huge;
    }
  }
}

.c-card--secondary {
  margin: 0;
  background: #f4f5f7;
  border-radius: 0;
  width: 100%;
  max-width: none;

  padding: (settings.$spacing-medium + settings.$spacing-small)
    settings.$spacing-medium;

  @include settings.font-size(13px, 1.2);

  &:not(:first-child) {
    margin-top: settings.$spacing-medium;
  }

  &.c-card--small-padding {
    padding: (settings.$spacing-small * 1.5) settings.$spacing-medium;
  }
}

.c-card--secondary-collapsible {
  &,
  .p-tabs--full-width &.c-card--secondary {
    flex: 0;
  }
  // .c-accordion__label-selfclosing
  .c-card__header > .c-card__header-buttons:last-child,
  .p-tabs--full-width & .c-card__header > .c-card__header-buttons:last-child {
    margin-bottom: 0;
  }

  // .c-accordion__label-selfclosing
  .c-card__header.c-card__header--secondary {
    flex-direction: row;
  }

  .p-tabs--full-width & .c-card__body {
    overflow: visible;
    flex: 0 0 auto;
  }
}

.c-card--edit-test-result {
  margin-top: 0;

  .c-list__form-with-select .c-input__group-benchmarks {
    .c-input__group.c-input__group-select:first-child:last-child {
      //   min-width: 100% !important;
      width: 50%;
      min-width: 0 !important;
      flex: auto;
      margin-left: 0;
    }
  }

  .c-input__wrapper.c-menu__wrapper.c-input__wrapper--select {
    font-family: settings.$font-family-regular;
  }

  .c-list--sublist
    .c-list__item-small-editable
    .c-list__item-editing-area.c-list__item-with-slider {
    .c-list__item-small-editing-buttons,
    .c-list__item-small-editing-area {
      width: 250px;
      min-width: 100%;
    }

    form {
      flex: auto;
    }
  }

  @include mq.mq($until: small) {
    // .c-list__form-with-select .c-input__group-benchmarks {
    //   .c-input__group.c-input__group-select:first-child:last-child {
    //     min-width: 100% !important;
    //   }
    // }

    // .c-list--with-dropdown {
    //   .c-list__item-editing-area,
    //   .c-list__item-small-editing-area {
    //     &.c-list__form-with-select
    //       .c-input__group-benchmarks
    //       .c-input__group.c-input__group-select {
    //       margin-top: 8px;
    //       margin-bottom: 8px;
    //     }
    //   }
    // }

    .c-list--sublist .c-list__item-small-editable {
      .c-list__item-small-editing-buttons,
      .c-list__item-small-editing-area {
        max-width: none;
        width: 100%;
      }
    }
  }

  @container tabs-container (inline-size < 486px) {
    .c-list__item-small-editing-area.c-list__item-small-edited-area-select {
      align-items: stretch;
    }
  }

  .c-list__item-small-editing-area.c-list__item-small-edited-area-select {
    .c-list__item-result:first-child,
    .c-list__item-result:last-child {
      align-self: stretch;
      width: calc(50% - 8px);
    }

    .c-list__item-result:first-child:last-child {
      min-width: 100%;
    }
  }

  @container tabs-container (inline-size > 628px) {
    .c-list--sublist .c-list__item-small-editable {
      .c-list__item-small-editing-buttons,
      .c-list__item-small-editing-area {
        min-width: 50%;
        max-width: 50%;
        margin-right: 24px;
      }
    }

    .c-list--sublist .c-list__item-small-editable {
      .c-list__item-small-editing-buttons,
      .c-list__item-small-editing-area {
        max-width: 70%;
        // margin-top: settings.$spacing-small;
        // margin-bottom: settings.$spacing-small;
      }
    }

    .c-list__item-read-only
      .c-list--sublist
      .c-list__item-small-editable
      .c-list__item-editing-area:not(.c-list__item-with-slider) {
      .c-list__item-small-editing-area {
        min-width: 0;
        margin-right: 0;
        width: 100%;
        display: flex;
        align-items: stretch;
        flex-wrap: nowrap;
      }
    }

    .c-list__item-one-line
      .c-list__item-small-editing-area.c-list__item-small-edited-area-select {
      //   max-width: 50%;
      min-width: calc(100% - 16px) !important;
      margin-left: 0;
      margin-left: 0;
      margin-right: 0;

      .c-list__item-result:first-child,
      .c-list__item-result:last-child {
        // outline: 2px solid orange;
        max-width: none;
        min-width: auto;
        width: 100%;
      }
    }
  }
}

.c-card__list-packages {
  display: flex;

  .c-card__list-packages-item:not(:last-child) {
    margin-right: settings.$spacing-small;
  }

  .c-card {
    margin-bottom: 0;
  }
}

.c-card__list-packages-modal {
  justify-content: center;
  flex-wrap: wrap;

  .c-card__list-packages-item {
    margin-right: 0;
  }
}

.c-card__row-wrapper {
  display: flex;

  > * {
    margin-bottom: 0;
  }

  > * + * {
    margin-left: settings.$spacing-small;
  }
}

.c-card--panel {
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0;
  margin-bottom: 0;

  .c-card__header {
    height: 140px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-end;
  }

  &.c-modal__content:not(.c-card--filter) .c-card__body--modal {
    height: calc(100% - 140px);

    > * {
      padding: 0;
    }

    > div > div {
      margin-top: -#{settings.$spacing-small};
    }
  }
}

.c-card--package {
  display: flex;
  justify-content: center;
  align-items: center;
  width: (settings.$spacing-huge * 2) + settings.$spacing-medium +
    settings.$spacing-small;
  height: (settings.$spacing-huge * 2) + settings.$spacing-medium +
    settings.$spacing-small;

  max-width: none;
  border: solid 1px settings.$color-neutral;
  border-radius: settings.$tiny-radius;

  background-color: settings.$color-neutral-x-dark;

  .c-button--disabled & {
    opacity: 0.5;

    &:hover,
    &:active,
    &:focus {
      background-color: settings.$color-neutral-x-dark;
      opacity: 0.5;
    }
  }

  .c-card__body {
    color: settings.$color-neutral-x-light;
  }

  .c-card__sportkompas,
  .c-card__mts {
    font-family: settings.$font-family-bold;
    font-weight: 900;
    transform-origin: 0;
    transform: scale(1.25, 1.15);
    margin-bottom: settings.$spacing-small;

    @include settings.font-size(20px);

    span {
      display: block;
      font-family: settings.$font-family-light;
      font-weight: 100;
      transform-origin: 0;
      transform: scale(0.8, 0.9);
    }
  }

  .c-card__package-name {
    margin-bottom: 0;
    background: settings.$color-neutral-x-light;
    display: inline-block;
    color: settings.$color-secondary;
    font-family: settings.$font-family-bold;
    padding: settings.$spacing-mini settings.$spacing-tiny;

    @include settings.font-size(12px);
  }
}

.c-card--empty-home {
  display: flex;
  flex-direction: column;
  flex: 1;

  .c-card__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.c-card--empty {
  margin: 0;
  width: 100%;
  max-width: 100%;

  display: flex;
  align-items: center;

  .c-card__body--empty {
    display: flex;
    justify-content: center;
  }
}

.c-card--center-center {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 0;

  height: 100%;
  position: static;

  .c-card__header + .c-card__body {
    padding-bottom: settings.$spacing-huge * 2;
  }

  .c-card__body {
    margin: auto;
    width: auto;
  }
}

.c-card--logo {
  background-position: center;
  background-size: cover;
}

.c-card--in-button {
  border-radius: settings.$small-radius;
  height: (settings.$spacing-huge * 2) + settings.$spacing-medium +
    settings.$spacing-small + settings.$spacing-tiny + settings.$spacing-mini;
  width: (settings.$spacing-huge * 2) + settings.$spacing-medium +
    settings.$spacing-small + settings.$spacing-tiny + settings.$spacing-mini;
  transition: all 0.2s ease-in-out;

  .c-icon--huge {
    width: settings.$huge-icon + settings.$spacing-small;
    height: settings.$huge-icon + settings.$spacing-small;
  }

  .c-card__body {
    margin-bottom: 0;
  }

  .c-card__title {
    font-family: settings.$font-family-bold;
    font-weight: normal;
    margin: settings.$spacing-small auto;
    text-transform: capitalize;

    @include settings.font-size(13px, 1.2);
  }

  .c-card__subtitle {
    font-family: settings.$font-family-light;
    font-weight: normal;
    text-transform: none;

    @include settings.font-size(13px, 1.2);
  }
}

.c-card__header {
  width: 100%;
  padding: settings.$spacing-medium settings.$spacing-small;
  background-color: settings.$color-primary-dark;
  color: settings.$color-neutral-x-light;
  text-align: center;
}

.c-card__header--primary {
  background-color: settings.$color-neutral-x-light;
  color: settings.$color-primary-dark;
  padding: settings.$spacing-large (settings.$spacing-medium * 1.5);
}

.c-card__header--secondary {
  padding: 0;
  background-color: inherit;
  color: inherit;
  text-align: left;
  display: flex;
  //   flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: settings.$spacing-medium;

  .c-card__body:not(.c-card__body-separator) + & {
    margin-top: settings.$spacing-large;
  }
}

.c-card__header--left {
  text-align: left;
}

.c-card__header-title {
  margin-bottom: 0;
  font-family: settings.$font-family-bold;
  color: settings.$color-neutral-x-dark;

  @include settings.font-size(24px, 1.3);

  > span.c-card__header-highlight {
    color: settings.$color-neutral-dark;
    border-bottom: 2px solid settings.$color-primary-dark;
  }

  .c-card__header--secondary & {
    @include settings.font-size(13px, 1.5);
  }
}

.c-card__header-title--small {
  @include settings.font-size(13px, 1.5);
}

.c-card__header--secondary .c-card__header-title.c-card__header-title--label {
  font-family: settings.$font-family-bold;
  color: settings.$color-neutral-dark;
  text-transform: uppercase;

  margin-bottom: 0;

  @include settings.font-size(10px, 1.2);
}

.c-card__header-subtitle {
  font-style: normal;
  width: 100%;
  margin-bottom: 0;
  font-family: settings.$font-family-light;
  font-weight: 300;
  max-width: 28rem;

  @include settings.font-size(13px, 1.5);
}

.c-card__header-buttons {
  display: flex;
  align-items: center;

  .c-button:not(:first-child):not(.c-button--menu):not(.c-button--toggletip) {
    margin-left: settings.$spacing-small;
  }
}

.c-card__menu {
  width: 100%;
  text-transform: uppercase;
  background-color: settings.$color-primary-dark;
  color: settings.$color-neutral-x-light;
  font-family: settings.$font-family-regular;

  @include settings.font-size(12px);

  button {
    &.active {
      span {
        font-weight: normal;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: settings.$color-primary;
      }
    }
  }
}

.c-card__body {
  width: 100%;
  padding: settings.$spacing-medium;
  margin-bottom: settings.$spacing-medium;
  line-height: 1.4;
}

.c-card__body--primary {
  background-color: settings.$color-neutral-light;
  margin-bottom: 0;
  //   padding: settings.$spacing-large;

  @include mq.mq($until: small) {
    padding: settings.$spacing-large settings.$spacing-medium;
  }

  @include mq.mq($from: small) {
    padding: settings.$spacing-large settings.$spacing-huge;
  }
}

.c-card__body--secondary {
  padding: 0;
  color: settings.$color-primary-dark;
  @include settings.font-size(13px, 1.2);
  margin-bottom: 0;

  .c-panel--error {
    margin-top: 0;
    min-height: 0;
    background: inherit;
    border: 0;
  }
}

.c-card__body--modal {
  position: relative;
  padding: (settings.$spacing-medium + settings.$spacing-small)
    settings.$spacing-small;
  height: calc(
    100dvh - #{settings.$spacing-huge * 6 + settings.$spacing-large +
      settings.$spacing-medium + settings.$spacing-small}
  );
  overflow-y: auto;
  overscroll-behavior: contain;

  box-shadow: inset 0 -7px 10px -16px settings.$color-neutral-x-dark;

  &.c-card__body--modal-with-overflow {
    overflow-y: visible;
    overscroll-behavior: contain;
  }

  .c-list:not(.c-list--dnd-preview):not(.c-list--cue):not(
      .c-list--virtualized
    ):not(.c-list--on-modal) {
    overflow-y: scroll;
    height: calc(100% - #{settings.$spacing-medium * 1.5});
    margin: (settings.$spacing-medium * 1.5) (-#{settings.$spacing-small}) 0;
    padding: 0 24px;
  }

  > .c-list:not(.c-list--dnd-preview):not(.c-list--cue):not(
      .c-list--virtualized
    ):first-child:last-child {
    height: 100%;
    margin: 0 (settings.$spacing-small * -1) 0;
    padding: settings.$spacing-medium (settings.$spacing-medium * 1.5)
      settings.$spacing-large;
  }

  .c-list__body {
    margin-bottom: 0;
  }

  .c-list--dnd-preview:not(:last-child) {
    margin-bottom: settings.$spacing-large;
  }

  .c-list--draggable-disabled {
    max-height: settings.$spacing-huge * 4;
    margin: 0;
    padding: 0 settings.$spacing-medium;

    .c-list__item-small-editing-area {
      justify-content: flex-end;
    }
  }

  &:not(.c-card__body--with-header) {
    > .c-card__header,
    > .c-input__group,
    > .o-layout,
    > *:not(.c-accordion-options):not(.c-file-upload--state) {
      padding: 0 settings.$spacing-medium;
    }
  }

  & + & {
    border-top: 12px solid settings.$color-neutral-x-light;
  }

  .c-input__group:last-child:not(:first-child),
  .c-list__item:last-child:not(:first-child) {
    margin-bottom: 0;
  }

  @media (max-height: 800px) {
    height: calc(100dvh - #{settings.$spacing-huge * 5});
    min-height: settings.$spacing-huge * 5;
    max-height: settings.$spacing-large * 13.25;
    overflow-y: auto;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.c-modal__content {
  .c-card__body--modal {
    padding: (settings.$spacing-medium * 1.5) (settings.$spacing-medium * 1.5) 0;
    display: flex;
    flex-direction: column;

    :not(.c-list--cue) .c-list__body,
    :not(.c-list--virtualized) .c-list__body {
      margin-top: settings.$spacing-medium;
    }

    .c-list--cue .c-list__body {
      margin: 0;
    }
  }

  .c-card__body--with-header {
    padding: 0;
  }

  .c-card-modal__body-head .c-card__body--tools {
    padding: 0;
  }

  .c-card-modal__body-content > .c-list {
    height: 100%;
    margin: 0;
    padding: 0 settings.$spacing-medium 0 (settings.$spacing-medium * 1.5);
  }

  .c-card__body--modal .c-list__item:hover,
  .c-card__body--modal .c-list__item:active,
  .c-card__body--modal .c-list__item:focus,
  .c-card__body--modal .c-list__item:focus-within {
    z-index: 0;
  }
}

.c-card-modal__body-content {
  overflow: hidden;
  width: 100%;
  flex: 1 1 100%;
  padding: 0;

  .c-list .c-list__body {
    margin-bottom: settings.$spacing-medium * 1.5;
  }

  &::after {
    content: '';
    background: linear-gradient(to bottom, #0000 60%, #dfdfdf44);
    height: settings.$spacing-medium;
    position: absolute;
    bottom: 0;
    left: auto;
    right: auto;
    width: 100%;
    bottom: 0;
    margin-left: -#{settings.$spacing-medium};
  }
}

.c-card-modal__body-content-exercise {
  overflow-y: scroll;

  > .c-card__body-separator {
    border: 0;
    margin-bottom: settings.$spacing-medium * 1.5;

    > .c-input__label {
      display: none;
    }
  }
  > .c-card--remarks {
    padding: settings.$spacing-medium;

    .c-card__content {
      margin-left: settings.$spacing-medium;
    }

    .c-input__group.c-input--closed.c-input--read-only {
      margin: 0;
    }
  }

  .c-media {
    margin: 0;
    background: transparent;
    padding-bottom: 0;
  }

  &::after {
    margin-left: (settings.$spacing-medium * -1.5);
    margin-right: 0;
    width: 100%;
  }

  &,
  .c-card__body--with-header > & {
    padding: settings.$spacing-medium settings.$spacing-medium
      settings.$spacing-medium (settings.$spacing-medium * 1.5);
  }
}

.c-card-modal__body-content-collection {
  .c-list:not(.c-list--dnd-preview):not(.c-list--cue) {
    &:not(.c-list--virtualized) {
      margin-top: 0;
      height: 100%;
      padding-right: 12px;
    }
  }
}

.c-card-modal__body-head-breadcrumb {
  display: flex;
  align-items: center;

  > .c-icon {
    flex: 0 0 settings.$small-icon;
    margin-bottom: settings.$spacing-mini;
  }

  > *:not(:first-child) {
    margin-left: settings.$spacing-small;
  }
}

.c-card-modal__body-with-head {
  overflow-y: hidden;
  height: calc(100dvh - 360px);

  .c-card-modal__body-head {
    margin: -24px -8px 0 -16px;
    position: static;
  }

  @media (max-height: 800px) {
    min-height: 50%;
  }
}

.c-card-modal__body-scrollable {
  overflow-y: scroll;
  height: calc(100% - 20px);
  padding: 16px 8px 16px 24px;
  margin-left: -16px;
  margin-right: -8px;
}

.c-card__body--empty {
  margin-bottom: 0;
  padding: settings.$spacing-small;
  text-align: center;
  color: settings.$color-neutral-dark;
  max-height: calc(#{settings.$spacing-huge} * 5);

  .c-button--rounded {
    display: inline-flex;
  }

  // Icon on "Forgot your password" card:
  > .c-icon {
    display: block;
    margin: (
        settings.$spacing-large + settings.$spacing-medium +
          settings.$spacing-small
      )
      auto;
  }
}

.c-card__empty-label {
  color: settings.$color-neutral-dark;
}

.c-card__body--tools {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 0 settings.$spacing-medium;

  > * {
    margin: 0;
  }

  > *:not(.c-breadcrumb-wrapper) + *:not(.c-breadcrumb-wrapper) {
    margin: 0 !important;
    margin-left: settings.$spacing-small !important;
  }

  > *:not(.c-breadcrumb-wrapper) + div:not(.c-breadcrumb-wrapper):last-child {
    width: 100%;
    margin: 0 !important;
  }

  > .c-input__group {
    margin-bottom: 0;
  }

  > .c-breadcrumb-wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
    // margin-bottom: settings.$spacing-small;
    margin-top: settings.$spacing-medium;
    margin-left: 0;

    .c-breadcrumbs {
      margin: 0 0 0 settings.$spacing-small;
      flex-wrap: wrap;
      flex: auto;
    }

    .c-breadcrumbs__icon {
      display: none;
    }

    .c-breadcrumbs > .c-breadcrumbs__item {
      min-height: settings.$spacing-large;

      &:nth-child(2) {
        display: none;
      }
    }

    .c-breadcrumbs__item,
    .c-breadcrumbs__link {
      color: inherit;
      cursor: default;
    }

    + .c-input__group {
      margin: 0;
      flex: 1 1 100%;
    }
  }
}

.c-card__footer--primary {
  padding-bottom: settings.$spacing-huge;
  background-color: settings.$color-neutral-x-light;
  position: relative;

  .c-button__group {
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    left: 50%;
  }

  p {
    margin-bottom: 0;
    color: settings.$color-success;
    font-family: settings.$font-family-bold;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    padding: 0 settings.$spacing-medium;
    text-align: center;
  }
}

.c-card__footer--modal {
  padding-bottom: settings.$spacing-huge - settings.$spacing-tiny;
  box-shadow: settings.$shadow-top;
}

.c-card__footer--modal-larger {
  padding-bottom: settings.$spacing-huge;
}

.c-card--progress-bar {
  padding: settings.$spacing-medium;

  .c-card__header--secondary {
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: settings.$spacing-medium;
  }

  .c-card__body--secondary {
    color: settings.$color-neutral-dark;
    text-align: center;
    margin: 0 auto;
    padding: settings.$spacing-medium 0;
  }

  .c-card__header--secondary + .c-card__body--secondary {
    border-top: 1px solid settings.$color-neutral;
    padding-top: settings.$spacing-medium;
    padding-bottom: 0;

    & + .c-card__footer {
      margin-top: settings.$spacing-medium;
    }
  }

  .c-button {
    display: inline-flex !important;
    justify-content: center;
  }

  .c-list {
    padding: 0;
  }

  .c-list--cue {
    text-align: left;
  }

  .c-card__footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .c-icon {
      transform: rotate(180deg);
    }
  }

  .c-menu__wrapper {
    max-width: none;
    display: inline-flex;

    .c-button {
      justify-content: flex-start;
    }
  }

  &.c-card--feedback {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    .c-card__header--secondary {
      margin-bottom: 0;
    }

    .c-card__body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .c-button.c-button--primary.c-button--rounded {
      align-self: center;
    }
  }
}

.c-card__body-separator {
  border-bottom: settings.$spacing-medium solid settings.$color-neutral-x-light;
}

.c-card__header-separator {
  border-top: settings.$spacing-medium solid settings.$color-neutral-x-light;
}

.c-card__body__progress-bar {
  color: settings.$color-neutral-dark;
  text-align: center;
  margin: 0 auto;
  padding: (settings.$spacing-large) 0;

  > .c-button {
    display: inline-flex !important;
    justify-content: center;
  }
}

.c-card--full-height {
  min-height: calc(100dvh - 250px);
  display: flex;
  flex-direction: column;

  .c-card__body--secondary {
    flex: 1 0 100%;
  }
}

.c-card--multiple-body {
  padding: 0;
  margin-top: 0;
  //   margin-bottom: settings.$spacing-medium;

  .c-card__body,
  .c-card__header,
  .c-card__footer {
    padding-left: settings.$spacing-medium;
    padding-right: settings.$spacing-medium;
  }

  .c-card__header {
    // padding-top: settings.$spacing-small + settings.$spacing-medium;
    padding-top: settings.$spacing-medium;
    margin-bottom: settings.$spacing-small;
  }

  .c-card__footer {
    padding-bottom: settings.$spacing-small + settings.$spacing-medium;
  }

  > :not(.c-card__body--media):last-child {
    padding-bottom: settings.$spacing-small;
  }
}

.c-card__footer--secondary {
  display: flex;
  justify-content: flex-end;
  margin-top: settings.$spacing-large;

  .c-button {
    margin-left: settings.$spacing-medium;
  }
}

.c-card__footer-bottom-fixed {
  padding: settings.$spacing-medium settings.$spacing-large;
  box-shadow: 0 0 6px -1px settings.$color-neutral;
  z-index: 10;

  .react-tabs__tab-panel--selected > & {
    padding: (settings.$spacing-small * 1.5) settings.$spacing-large;
    position: absolute;
    bottom: 0;
    background: settings.$color-neutral-x-light;
    left: 0;
    right: 0;
  }

  .c-card--multiple-body & {
    position: sticky;
    bottom: 0;
    margin-left: -#{settings.$spacing-medium} !important;
    right: -#{settings.$spacing-medium};
    z-index: 10;
    background: inherit;
    padding-top: (settings.$spacing-small * 1.5);
    padding-bottom: (settings.$spacing-small * 1.5) !important;
    margin: 0;
    margin-left: 0px;
    width: calc(100% + 32px);
    // box-shadow: 0px -1px 1px -1px black;
  }

  .p-tabs--full-width .c-card--multiple-body & {
    margin-left: 0;
    left: settings.$spacing-small;
    right: 0;
    width: 100%;
  }
}

.c-card-modal {
  .c-list.c-list--cue {
    margin-top: 0;
    margin-bottom: 48px;
    overflow: visible;
    height: auto;
  }
}

.c-card--modal-small {
  width: 450px;
}

// Aux: to use in storybook
// == on modal props.autoheight
.c-card-modal__content-auto-height {
  &,
  &.c-modal__content {
    bottom: auto;
  }

  // == on modal props.autoheight > c-card__body
  .c-card__body {
    padding: (settings.$spacing-medium + settings.$spacing-small)
      settings.$spacing-small;
    height: auto;
    max-height: none;
    min-height: 0;
  }

  .c-card__body + .c-card__body {
    padding-bottom: settings.$spacing-large * 1.5;
  }

  .c-menu--open {
    z-index: 21;
  }
}

.c-card-modal__content-body-scroll {
  &,
  &.c-modal__content {
    bottom: auto;
  }

  .c-card__body--modal {
    padding: (settings.$spacing-medium + settings.$spacing-small)
      settings.$spacing-small 0;

    .c-loader {
      z-index: 0;
    }

    .c-card__body--tools {
      position: sticky;
      top: -24px;
      padding: 20px 24px 12px;
      background: inherit;
      //   margin: -24px -8px -24px -8px;
      width: calc(100% + 16px);
      z-index: 10;

      margin: -24px -8px 0 -8px;
      box-shadow: 0 0px 10px -7px;
    }

    &:has(> .p-tabs__tab-header:first-child) {
      padding-top: 0;
    }
  }

  // Modal Preview Exercise
  .c-input__label ~ .c-list.c-list--cue {
    margin-top: 0;
    // margin-bottom: 8px;
    margin-bottom: settings.$spacing-medium * 1.5;
    padding: 0 settings.$spacing-medium;
    flex: 1 0 auto;
  }

  .c-file-upload__video {
    margin-bottom: 24px;
  }

  .p-select-container__wrapper {
    padding: 0 settings.$spacing-medium;
  }

  .c-card__body--modal > *:last-child:not(.c-list--virtualized),
  .p-select-container__wrapper:last-child {
    margin-bottom: settings.$spacing-medium;
  }
  .p-select__multi-value:first-child {
    margin-left: 0 !important;
  }
}

.c-card-modal__content-body-max-height {
  .c-card__body {
    // max-height: 70vh;

    > :last-child {
      margin-bottom: settings.$spacing-small;
    }
  }
}

// === on modal props.alert >
.c-card-modal__content-alert {
  max-width: 400px;

  .c-card__body {
    padding-bottom: settings.$spacing-large + settings.$spacing-medium;

    p {
      font-family: settings.$font-family-light;
      color: settings.$color-primary-dark;
      margin-bottom: 0;
    }
  }

  .c-card__body--benchmarks {
    text-align: center;
    justify-content: center;
    padding: settings.$spacing-small + settings.$spacing-medium;
    min-height: 60px;

    .c-icon {
      margin-bottom: settings.$spacing-medium;
    }

    .c-button__group {
      margin-top: settings.$spacing-medium;
      justify-content: center;

      .c-button {
        text-transform: uppercase;
        font-family: settings.$font-family-regular;
      }
    }
  }
}

.c-card__body--benchmarks {
  p {
    margin-bottom: 8px;
    line-height: 1.4;
    color: settings.$color-primary-dark;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.c-card--tooltip {
  min-width: 250px;
  width: 100%;
  max-width: none;
  margin: 0;
  background-color: settings.$color-neutral-x-light;

  .c-card__header--secondary {
    margin-bottom: 0;
    padding: settings.$spacing-small settings.$spacing-medium;
    height: auto;

    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    // align-items: center;
  }

  .c-card__header-text-group {
    flex: 0 0 50%;
    display: flex;
    align-items: center;

    @include settings.font-size(13px);
  }

  .c-card__header-subtitle {
    flex: 1 0 auto;
    margin-left: settings.$spacing-medium;
    width: auto;
    text-align: right;
    @include settings.font-size(10px);
  }

  .c-card__body {
    padding: 0 settings.$spacing-medium settings.$spacing-medium;
    margin: 0;

    .c-progress-bar__group + .c-progress-bar__group {
      margin-top: settings.$spacing-tiny;
    }
  }
}

.c-card__header-text-group {
  flex: 1 0 auto;
  margin-right: settings.$spacing-small;
  margin-bottom: settings.$spacing-small;
}

.c-card__header-buttons {
  flex: 1 1 auto;
  justify-content: flex-end;
}

// === on modal props.benchmarksTableAlert
.c-card-modal__content-benchmark-table-alert {
  &,
  &.c-modal__content {
    bottom: auto;
    top: 50%;
    border: 0;
  }
}

.c-modal__overlay-benchmark-table-alert {
  &,
  &.c-modal__overlay {
    background-color: rgba(0, 0, 0, 0);
  }
}

.c-card--helper {
  background-color: settings.$color-neutral-light;
  border-radius: 0;
  bottom: auto;

  .c-menu__wrapper--as-modal &,
  .c-panel--home .c-menu__wrapper--as-modal & {
    max-width: 305px;
    width: 100%;
  }

  .c-card__header-title {
    text-align: left;
    @include settings.font-size(13px);
  }

  // == on modal props.autoheight > c-card__body
  .c-card__body {
    padding: settings.$spacing-medium;
    height: auto;
  }
}

.c-card--organisations {
  width: auto;
  height: 100%;
  max-width: none;
  padding: 0 0 0;
  margin-bottom: 0;

  .c-card__header {
    margin: 0;
    margin-bottom: settings.$spacing-medium + settings.$spacing-small;
    padding: 0;
  }

  .c-card__body--tools {
    flex-direction: column;
    align-items: center;

    > * + * {
      margin: 0 !important;
      margin-top: settings.$spacing-small !important;
    }

    @include mq.mq($from: small) {
      flex-direction: row;

      > * + * {
        margin: 0 !important;
        margin-top: 0 !important;
        margin-left: settings.$spacing-small !important;
      }
    }
  }

  .c-card__body {
    background: settings.$color-neutral-x-light;
    padding: 0;
    text-align: left;

    height: auto;
    padding: settings.$spacing-tiny settings.$spacing-medium;
    margin: 0;
    overflow-y: hidden;

    .c-input__group {
      margin-right: 0;

      &:last-child {
        @include mq.mq($from: small) {
          margin-bottom: 0;
        }
      }
    }

    @include mq.mq($from: small) {
      max-width: 480px;
      margin: auto;
      margin-top: 0;
    }
  }

  .c-card__body--modal {
    max-height: none;
  }
}

// Fix modal:
.c-card-modal__import-sporters {
  @media screen and (max-height: 700px) {
    min-height: 320px;
    top: auto;

    .c-card__body {
      min-height: calc(100dvh - 297px);
    }

    .c-file-upload--wrapper-states {
      padding-top: 0;
      justify-content: flex-start;
      min-height: auto;
    }

    .c-file-upload__message {
      max-width: 90%;
      margin-top: 0;

      & + & {
        margin-top: settings.$spacing-medium + settings.$spacing-small;
      }
    }
  }

  &.c-modal__content {
    width: calc(100% - 64px);
    top: 48px;
    margin-bottom: 0;
    max-width: none;

    .c-card__body--modal {
      box-shadow: none;
      padding: 0 (settings.$spacing-medium * 1.5) 0;
      overflow-x: auto;
    }

    .c-table-cell {
      min-height: 0;
      border-bottom: 4px solid #f4f5f7;
    }

    .c-table--benchmark.c-table--values.c-table--import {
      .c-table__header {
        background-color: inherit;
        box-shadow: none;

        .c-table-cell--header:first-child::before {
          content: '';
          background: #f4f5f7;
          width: 2px;
          height: 100%;
          position: absolute;
          left: -2px;
          top: 0;
          bottom: 0;
        }

        .c-table-cell--header:last-child::after {
          content: '';
          background: #f4f5f7;
          width: 2px;
          height: 100%;
          position: absolute;
          right: -2px;
          top: 0;
          bottom: 0;
        }
      }

      .c-table-cell.c-table-cell--header {
        padding: 0;

        .o-flex {
          padding: 8px 8px 6px;
          background-color: #f4f5f7;
          border-bottom: 1px solid settings.$color-neutral;
          min-height: 36px;
          justify-content: flex-start;
        }
      }

      .c-table__row:hover {
        background: inherit;
      }

      .c-table-cell:not(th) {
        border-bottom: 0;
      }

      .c-table .c-table-cell {
        cursor: default;
      }

      // Col RIJ
      .c-table__row .c-table .c-table-cell:first-child:not([colspan]) {
        width: 50px;
        padding: 0;
      }
      .c-table-cell.c-table-cell--header:first-child {
        min-width: 0;
        max-width: none;
        width: 50px;
        position: relative;
      }
      .c-table__row .c-table .c-table-cell:nth-child(2) {
        // text-align: left;
        width: 50px;
      }
      .c-table-cell.c-table-cell--header:nth-child(2) {
        min-width: 0;
        max-width: none;
        width: 50px;

        .o-flex {
          justify-content: center !important;
        }
      }
      .c-table__row .c-table .c-table-cell:nth-child(3) {
        width: 80px;
      }
      .c-table-cell.c-table-cell--header:nth-child(3) {
        min-width: 0;
        max-width: none;
        width: 80px;
      }
      .c-table__row .c-table .c-table-cell:nth-child(6) {
        width: 120px;
      }
      .c-table-cell.c-table-cell--header:nth-child(6) {
        min-width: 0;
        max-width: none;
        width: 120px;
      }
      .c-table__row .c-table .c-table-cell:nth-child(7) {
        width: 90px;
      }
      .c-table-cell.c-table-cell--header:nth-child(7) {
        min-width: 0;
        max-width: none;
        width: 90px;
      }

      > tbody
        > .c-table__row:not(.c-table__row--selected)
        + .c-table__row:not(.c-table__row--selected)
        > .c-table-cell::before {
        content: '';
        position: absolute;
        top: -5px;
        width: 100%;
        height: 1px;
        background: #d6dadc;
        z-index: 1;
      }

      .c-table__row--selected {
        > .c-table-cell {
          > .c-table {
            outline: 1px solid settings.$color-neutral;
            outline-offset: 0;
          }
        }

        .c-table {
          .c-table-cell {
            background: settings.$color-primary-light;
            color: settings.$color-primary-dark;
          }
        }
      }

      .c-table__row--error {
        > .c-table-cell > .c-table--editable .c-table-cell {
          color: settings.$color-error !important;
          opacity: 0.8;

          .c-input {
            color: settings.$color-error !important;
          }
        }
      }

      .c-table__row--warning {
        > .c-table-cell > .c-table .c-table__row:last-child .c-table-cell {
          //   background-color: rgba(settings.$color-notice-25, 0.2);
          color: settings.$color-error;
        }
      }

      .c-table__row:not(.c-table__row--selected) .c-table {
        .c-table-cell {
          color: settings.$color-primary-dark;
        }
      }
    }

    .c-table--compressed .c-table__row .c-table-cell {
      padding: 0 8px;
    }

    .c-table .c-input__group {
      height: auto;
      justify-content: center;
    }
    .c-table .c-input {
      height: 100%;
      max-height: none;
    }
    .c-table .c-input__wrapper {
      height: 100%;
    }

    tr .c-table-cell:first-child[colspan] {
      padding: 0;

      > div {
        padding: 4px 12px;
      }

      > div > div {
        display: flex;
        margin: 2px 0;
      }

      > div > div > svg {
        display: block;

        margin-right: 26px;
        margin-left: 55px;
      }
    }

    .c-table-cell:not([colspan]) > div:not([class]) {
      display: flex;
    }

    .c-table .c-input--checkbox + .c-input__label--checkbox {
      top: 0;
      bottom: 0;
      position: absolute;
    }

    .c-file-upload--wrapper-states {
      display: block;
      padding: settings.$spacing-medium 0 0;
      max-width: none;
      min-height: 0;
      min-width: 0;
      max-height: 100%;
      width: 100%;
      margin-top: 0;

      .c-table--benchmark.c-table--values.c-table--import {
        margin-bottom: settings.$spacing-large;

        .c-table--editable {
          margin-bottom: settings.$spacing-small;
          background: transparent;
          border-collapse: collapse;
          border-spacing: 0;

          .c-table-cell:not(:first-child) {
            padding: settings.$spacing-tiny settings.$spacing-small;
          }

          //   .c-table__row:first-child:last-child {
          //     .c-table-cell:not(:first-child) {
          //       padding: settings.$spacing-tiny settings.$spacing-small;
          //     }
          //   }
        }

        .c-table-cell:not(th) {
          border-bottom: 0;
        }
      }
    }

    .c-file-upload__message {
      max-width: 42rem;
      text-align: left;
      margin-bottom: settings.$spacing-medium;
      margin-top: 0;

      width: 100%;
      background: #f4f5f7;
      max-width: none;
    }

    .c-file-upload__content {
      max-height: none;
      margin-left: -24px;
      padding-left: 24px;
      padding-right: 16px;
      width: calc(100% + 48px);
      padding-bottom: settings.$spacing-small;
    }

    div.c-card__body:nth-child(3) {
      height: calc(100dvh - 272px);
    }
  }
}

.c-card-body__copy-empty {
  max-width: 60%;
  margin: 0 auto 20px;
  text-align: center;
  color: settings.$color-neutral-dark;
}

.c-card:not(.c-card--filter) .c-card__body-wrapper {
  .c-input__label {
    color: settings.$color-primary-dark;
  }

  > :first-child {
    margin-top: settings.$spacing-medium;
  }
}

.c-card--filter {
  container: modal-filter-container / inline-size;

  .c-card__header {
    box-shadow: settings.$dragging-shadow;
  }

  .c-card__footer {
    box-shadow: settings.$dragging-shadow;
    padding: 16px !important;
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: -24px;
  }
}

.c-card--multiple-body-with-tags {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: settings.$color-neutral-x-light;

  .c-card__header {
    padding-bottom: 12px;
    margin-bottom: 0;
  }
  .c-card__header--secondary,
  .c-card__body--secondary {
    background-color: settings.$color-neutral-light;
  }
  .c-card__footer-bottom-fixed {
    margin-top: auto !important;
    background: settings.$color-neutral-x-light;
  }
}

// .c-card__body--remarks,
.c-card--remarks {
  background-color: settings.$color-primary-10;
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;

  .c-icon:not(.c-icon--x-huge) {
    width: 48px;
    height: 48px;
  }

  .c-card__content {
    width: 100%;
  }
}

.c-card--remarks-alert {
  background-color: rgba(settings.$color-error, 0.05);
  .c-input__group:last-child {
    margin-bottom: 0;
  }
  .c-card__content {
    padding: settings.$spacing-medium;
  }
}

.c-card__header--remarks {
  background-color: settings.$transparent;
}

.c-card-modal__body-head {
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 1;
  background: settings.$color-neutral-light;
  box-shadow: 0 0 10px -6px settings.$color-neutral-x-dark;
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex: 0 0 auto;

  .c-button__group {
    margin: 0;
  }

  &,
  .c-card__body--with-header > & {
    padding: settings.$spacing-medium (settings.$spacing-medium * 1.5)
      (settings.$spacing-small * 1.5);
  }
}

.c-card-modal__body-head--small {
  padding: (settings.$spacing-small * 1.5) settings.$spacing-medium
    (settings.$spacing-small * 1.5) (settings.$spacing-medium * 1.5);
}

.c-card-modal__content-body-scroll
  .c-card__body--modal.c-card__body--with-tabs {
  padding: 0;

  > .p-tabs {
    padding: 0;
    height: 100%;
    margin-bottom: 0 !important;

    > .react-tabs {
      height: 100%;

      .react-tabs__tab-panel--selected {
        max-height: 100%;
        margin: 0;
        padding: 0;

        .p-tabs__active-tab-content-wrapper.p-tabs__with-header {
          margin: 0 !important;
          padding: 0 !important;

          > .c-input__group {
            // margin: 12px auto 0;
            // box-shadow: settings.$shadow-bottom;
            // padding-bottom: 16px;
            // width: 100%;
            // z-index: 1;

            .c-input__wrapper {
              margin: 0 24px;
            }
          }

          > .c-breadcrumbs-wrapper--for-lists {
            padding: 16px 24px 0;
          }
        }
      }
    }
  }

  .c-list--on-modal {
    margin: 0 !important;
    padding: 12px 16px 24px 24px;

    .c-list__body {
      margin-bottom: 16px !important;
    }
  }
}

.c-card-modal__content-body-scroll > form {
  .c-card__body--modal.c-card__body--with-tabs {
    height: auto;

    .p-tabs
      .react-tabs__tab-panel--selected
      > .p-tabs__active-tab-content-wrapper {
      overflow-y: auto;
      margin: 0;
      padding: 16px 24px 0;

      > .c-list.c-list--on-modal {
        padding: 0 24px;
        margin-left: -24px !important;
        margin-right: -24px !important;
        max-width: calc(100% + 48px);
        margin-top: -16px !important;
        // min-height: calc(100% + 32px);
        max-height: none;

        .c-list__body {
          margin-bottom: 32px !important;
        }
      }
    }
  }

  .c-card__body-separator {
    border-bottom: 1px solid settings.$color-neutral;
    padding-bottom: 8px; // 18
    margin-bottom: 8px; // 4
  }

  .c-card__body-separator + .c-card__header.c-card__header--secondary {
    margin-top: 16px;
    margin-bottom: 12px;
  }
}

/* Refactoring */
.r-card__body {
  .c-modal__content & {
    background-color: settings.$color-neutral-light;
    margin-bottom: 0;
    padding: 16px 24px 8px;
  }

  .c-card-modal__content-auto-height.c-modal__content & {
    padding-bottom: 24px;
  }

  & + & {
    border-top: 12px solid #fff;
    padding-bottom: 0;
  }

  .r-list {
    padding: 0 24px 24px;
    margin: 0 -24px;
    max-height: calc(100dvh - 520px);
    overflow-y: auto;
  }

  .c-card__header-title {
    @include settings.font-size(13px, 1.5);
  }

  .c-list__item {
    overflow: hidden;
  }

  .c-list__item-label {
    padding: 8px 16px;
  }

  .r-list__item-meta {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-family: settings.$font-family-regular;
  }
}
