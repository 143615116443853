@use '_scss/settings';
@use '_scss/07-plugins/mq' with (
  $breakpoints: settings.$mq-breakpoints
);

.c-time-navigator {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  position: relative;
}

.c-time-navigator__actual {
  width: 50%;
  margin: auto;

  .c-input__group {
    margin-bottom: 0;
  }

  .c-input__label {
    text-align: center;
  }

  .c-button--icon-right {
    padding-right: settings.$spacing-medium * 1.5;

    .c-icon {
      position: absolute;
      right: settings.$spacing-small;
    }
  }

  @container tabs-container (inline-size < 518px) {
    min-width: 120px;
  }

  @include mq.mq($until: tiny) {
    max-width: calc(100% - 96px);
    width: 100%;
  }
}

.c-time-navigator__prev,
.c-time-navigator__next {
  position: absolute;
  bottom: 0;
}

.c-time-navigator__prev {
  left: 0;
  right: auto;

  .c-icon {
    transform: scaleX(-100%);
  }

  .c-button--icon-expandable {
    .c-icon {
      position: absolute;
      margin: settings.$spacing-small;
    }
  }
}

.c-time-navigator__next {
  left: auto;
  right: 0;

  .c-button--icon-expandable {
    right: 0;

    &.c-button--icon-right {
      .c-button__label {
        right: settings.$spacing-large;
        left: auto;
      }

      .c-icon {
        margin-left: auto;
        margin-right: settings.$spacing-small;
      }
    }
  }
}
