@use '_scss/settings';

.c-organisation {
  width: auto;
  min-width: 100%;

  border: 1px solid transparent;
  padding: settings.$spacing-tiny 0;

  display: flex;
  align-items: center;
  line-height: 1.7;
  text-decoration: none;

  font-family: settings.$font-family-bold;
  color: settings.$color-neutral-dark;
  background: settings.$transparent;

  margin-top: settings.$spacing-small;

  img {
    max-width: 24px;
    max-height: 24px;
    margin-right: 12px;
  }

  .c-icon {
    margin-left: settings.$spacing-tiny;
    margin-right: 0;
  }

  .c-card-modal__body-head-breadcrumb & {
    margin-top: 0;
    min-width: 0;
    width: 100%;
  }

  .c-input__group-select-tags + & {
    display: inline-block;
    margin-top: 6px;
    margin-bottom: -2px;
    align-self: flex-start;
    min-width: auto;

    .c-icon {
      margin-left: 2px;
      margin-right: 0;
      margin-bottom: -2px;
    }

    &:focus-visible {
      z-index: 22;
    }
  }
}
