@use '_scss/settings';
@use '_scss/07-plugins/mq' with (
  $breakpoints: settings.$mq-breakpoints
);

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.5s ease 0.1s;
  overflow: auto;

  .c-card--panel {
    transform: translateX(100%);
    transition: transform 0.1s ease 0.5s;
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;

  .c-card--panel {
    transform: translateX(0);
  }
}

.ReactModal__Overlay--before-close {
  opacity: 0;

  .c-card--panel {
    transform: translateX(100%);
  }
}

.c-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 101;
  background-color: rgba(settings.$color-neutral-x-dark, 0.5);
  overflow-x: hidden;

  @include mq.mq($from: small) {
    left: 72px;
  }
}

.c-modal__content {
  position: absolute;
  border: 1px solid #ccc;
  background: #fff;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
  outline: none;

  top: settings.$spacing-huge + settings.$spacing-huge +
    settings.$spacing-large - settings.$spacing-tiny;
  bottom: settings.$spacing-tiny + settings.$spacing-medium +
    settings.$spacing-huge;

  @media (max-height: 800px) {
    top: settings.$spacing-huge + settings.$spacing-large;
    bottom: auto;
  }

  .c-input--expandable {
    width: auto;
  }

  .c-card__header {
    border-top-left-radius: settings.$spacing-small;
    border-top-right-radius: settings.$spacing-small;
  }

  .c-card__header--long-content {
    max-height: 126px;
    position: relative;
    transition: max-height 0.2s ease-in-out;
    overflow: hidden;

    .c-card__header-wrapper::after {
      content: '';
      background: linear-gradient(to bottom, transparent 25%, #fffd 80%, #fff);
      height: 16px;
      width: 100%;
      position: absolute;
      z-index: 1;
      left: 0;
      bottom: 0;
    }

    & + .c-card__body--modal {
      .c-card__body--tools {
        box-shadow: none;
        padding-bottom: 0;

        .c-breadcrumbs-wrapper--for-lists {
          padding-bottom: 0;
        }
      }

      .c-list__body {
        margin-top: 0;
      }
    }
  }

  .c-card__footer {
    border-bottom-left-radius: settings.$spacing-small;
    border-bottom-right-radius: settings.$spacing-small;
    z-index: 20;
  }

  .c-button__modal {
    position: absolute;
    right: settings.$spacing-medium;
    top: settings.$spacing-medium;
  }

  &.c-card--panel {
    transition:
      opacity 0.5s ease-in-out,
      transform 0.5s ease-in-out;
    bottom: 0;
    top: 0;
    width: 90%;

    .c-card__footer > p {
      max-width: 256px;
      margin: auto;
      text-align: center;

      @include settings.font-size(12px);
    }

    @include mq.mq($from: medium) {
      width: 70%;
    }

    @include mq.mq($from: large) {
      width: calc(50% - 36px);
    }
  }

  .p-tabs .react-tabs__tab-list {
    overflow: visible;
    margin-left: 24px;
    margin-right: 24px;

    &::after {
      left: 0;
    }
  }

  .p-tabs .p-tabs__active-tab-content-wrapper > .c-list {
    padding: 0 24px;
    margin-left: 0;
    margin-right: 0;
  }

  .p-tabs .p-tabs__with-header > .c-input__group {
    margin: 12px 0 0;
    padding: 0 0 12px;
  }
}

.c-card__header--long-content:focus,
.c-card__header--long-content:hover,
.c-button:hover + .c-card__header--long-content,
.c-button:focus + .c-card__header--long-content,
.c-card__header--long-content:has(
    ~ .c-card__footer .c-button--primary:not(.c-button--disabled):focus
  ),
.c-card__header--long-content:has(
    ~ .c-card__footer .c-button--primary:not(.c-button--disabled):hover
  ) {
  overflow: visible;

  .c-card__header-wrapper {
    height: 100%;
    max-height: 420px;
    min-height: 132px;
    overflow: auto;
    background: white;
    z-index: 11;
    position: relative;
    box-shadow: 0 0 10px -5px;
    top: -32px;
    left: -24px;
    border-radius: 8px;
    padding: 32px 24px 16px;
    width: calc(100% + 48px);
    // min-height: 202px;

    &::after {
      content: none;
    }
  }

  & + .c-card__body--modal::before {
    content: '';
    position: absolute;
    top: -20px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000a;
    z-index: 1;
  }

  & ~ .c-card__body--modal {
    .p-tabs__tab-header,
    .c-card__body--tools {
      z-index: 0;
    }
  }
}

.c-card-modal__overflow-visible .c-card__body {
  overflow-y: visible;
}

.c-card-modal__overflow-scroll .c-card__body {
  overflow-y: scroll;

  .o-layout {
    padding: 0 !important;
  }

  .react-datepicker {
    margin-bottom: settings.$spacing-large;
  }
}

.c-modal__content-auto-width {
  width: auto;
}

.c-modal__content-bottom-huge {
  bottom: settings.$spacing-huge;
  margin-bottom: 0;
}

.c-modal__content-bottom-auto {
  bottom: auto;
}

.c-card--filter {
  max-height: 100dvh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .c-card__header {
    height: auto;
  }

  .c-card__header--primary {
    padding: 24px 24px;
  }

  .c-button--absolute-top-right {
    right: 24px;
    top: 24px;

    .c-icon {
      width: 24px;
      height: 24px;
    }
  }

  .c-card__body--modal {
    height: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: settings.$spacing-medium (settings.$spacing-medium * 1.5)
      (settings.$spacing-medium * 1.5);
    max-height: calc(100dvh - 100px);
    overflow-y: scroll;

    > .c-card__body-wrapper {
      flex: 0 0 auto;
      margin-bottom: 16px;
      background: settings.$color-neutral-light;
      padding-top: 16px;
    }
  }

  .c-input__group-select {
    .c-input__group-select {
      margin-bottom: 24px;
    }
  }

  .c-card__footer--secondary {
    // position: fixed;
    // bottom: 16px;
    // z-index: 50;
    // right: 24px;

    margin-top: auto;
  }
}

// Modal for forgot password:
// Icon + Text + CTA
.c-card--icon-message-cta {
  .c-card__body--empty {
    padding: settings.$spacing-small settings.$spacing-medium
      settings.$spacing-large;
  }

  .c-icon {
    margin: settings.$spacing-large auto;
  }

  p {
    max-width: 32rem;
    margin: auto auto settings.$spacing-medium;
  }

  .c-card__main-message {
    color: settings.$color-success;
    font-family: settings.$font-family-bold;
  }
}

// REFACTORING:
.r-modal {
  --shadow-modal: 0 0 15px -7px rgba(settings.$color-primary-dark, 0.5);
  --shadow-in-modal: 0 0 10px -8px #34373e;

  top: settings.$spacing-huge * 2;
  margin: 0 auto 16px;
  border-radius: 8px;
  width: 90%;
  max-width: none;

  box-shadow: var(--shadow-modal);

  position: absolute;
  background-color: settings.$color-neutral-x-light;
  overflow: hidden;
  outline: none;
  bottom: auto;

  @include mq.mq($from: small) {
    width: 700px;
    max-width: 90%;
  }

  // Vertical check
  @media (max-height: 800px) {
    top: settings.$spacing-huge * 1.5;
  }
}

.r-modal__header {
  width: 100%;
  background-color: #fff;
  color: #071649;
  padding: 32px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  box-shadow: var(--shadow-in-modal);
  position: relative;
  z-index: 1;
}

.r-modal__body {
  width: 100%;
  background-color: #f4f5f7;
  margin-bottom: 0;

  position: relative;
  height: calc(100dvh - 440px);
  overflow-y: auto;
  overscroll-behavior: contain;

  display: flex;
  flex-direction: column;

  padding: 0 8px;

  // Vertical check
  @media (max-height: 800px) {
    height: calc(100dvh - 320px);
    overflow-y: auto;

    min-height: 320px;
    max-height: 424px;
  }
}

.c-list--on-modal {
  height: 100%;
  padding: 16px 24px 0;
  max-width: calc(100% + 16px);
  margin: 0 -8px;
}

.r-modal__footer {
  background-color: settings.$color-neutral-x-light;
  position: relative;
  box-shadow: var(--shadow-in-modal);
  padding-bottom: 64px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 20;

  .c-button__group {
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}
