@use '_scss/settings';
@use '_scss/07-plugins/mq' with (
  $breakpoints: settings.$mq-breakpoints
);

.c-breadcrumbs {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0 0 settings.$spacing-small;
  flex: 1 0 100%;
  flex-wrap: wrap;
}

.c-breadcrumbs-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .c-breadcrumbs {
    flex: auto;
  }
}

.c-breadcrumbs-wrapper--for-lists {
  align-items: center;
  padding-bottom: settings.$spacing-small;
  padding-top: settings.$spacing-small * 1.5;
  margin-bottom: 0;
  z-index: 1;

  .c-breadcrumbs {
    margin: 0 settings.$spacing-small;
  }

  & + .c-list {
    margin-top: 0;
  }

  > .c-icon {
    flex: 0 0 settings.$small-icon;
  }
  > .c-organisation {
    margin-top: 0;
    min-width: 0;
    width: 100%;
  }

  > *:not(:first-child) {
    margin-left: settings.$spacing-small;
  }

  .c-breadcrumbs__icon {
    display: none;
  }
}

.c-breadcrumbs--benchmarks {
  .c-breadcrumbs__item {
    border: 1px solid transparent;
    border-top-color: settings.$color-primary-dark-10;
    border-bottom-color: settings.$color-primary-dark-10;

    // margin-left: -4px;
    margin-right: 0;
    padding: settings.$spacing-small settings.$spacing-small
      settings.$spacing-small settings.$spacing-tiny;

    &:nth-child(2) {
      padding: settings.$spacing-small settings.$spacing-small
        settings.$spacing-small settings.$spacing-medium;
    }

    &:first-child {
      background-color: settings.$color-primary;
      margin-right: settings.$spacing-medium;
      border-left-color: settings.$color-primary;
      border-top-color: settings.$color-primary;
      border-bottom-color: settings.$color-primary;
      border-right: 1px solid transparent;
      padding: 0;
      margin-right: 0;

      .c-breadcrumbs__link {
        color: settings.$color-neutral-x-light;
        padding: settings.$spacing-small settings.$spacing-small
          settings.$spacing-small 14px;
      }

      &:after {
        content: '';
        width: settings.$spacing-small;
        position: absolute;
        background: transparent;
        border-top: settings.$spacing-medium solid transparent;
        border-left: 10px solid settings.$color-primary;
        border-bottom: settings.$spacing-medium solid transparent;
        height: 100%;
        right: -11px;
      }
    }

    &:last-child {
      border-right-color: settings.$color-primary-dark-10;
    }

    &:not(:first-child):not(:last-child) {
      &:after {
        right: -3px;
        top: calc(50% - 1px);
        transform: translateY(-50%);
      }
    }
  }
}

.c-breadcrumbs__item {
  position: relative;
  margin-right: settings.$spacing-tiny;
  color: settings.$color-primary;
  display: flex;
  align-items: center;

  &:not(:first-child):not(:last-child) {
    padding-right: settings.$spacing-small;

    &:after {
      content: '';
      width: settings.$spacing-medium + settings.$spacing-mini;
      height: settings.$spacing-medium + settings.$spacing-mini;
      background-image: url('~/src/assets/images/svgstore/breadcrumb.svg');

      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      right: -#{settings.$spacing-tiny};
    }
  }

  &:nth-last-child(n + 3) {
    @include mq.mq($until: small) {
      display: none;
    }
  }
}

.c-breadcrumbs__icon {
  width: settings.$spacing-medium;
  height: settings.$spacing-medium;
  margin-right: settings.$spacing-tiny;
  padding: 0;

  svg {
    width: settings.$spacing-medium;
    height: settings.$spacing-medium;
  }
}

.c-breadcrumbs__link {
  font-family: settings.$font-family-bold;
  color: settings.$color-primary;
  padding: 0 settings.$spacing-small 0 1px;
  text-decoration: none;
}
