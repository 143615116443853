@use '_scss/settings';

.p-react-datepicker.react-datepicker-wrapper {
  width: 100%;
}

.p-react-datepicker + .react-datepicker__tab-loop {
  .react-datepicker-popper {
    z-index: 6;

    &[data-placement^='bottom'] {
      .react-datepicker__triangle::before {
        border-bottom-color: settings.$color-neutral;
      }

      .react-datepicker__triangle::after {
        border-bottom-color: settings.$color-neutral-light;
      }
    }

    .c-card-modal__content-auto-height & {
      z-index: 21;
    }
  }

  .react-datepicker {
    font-family: settings.$font-family-regular;

    box-shadow: 0 3px 10px -6px settings.$color-neutral-x-dark;
    border-color: settings.$color-neutral;
  }

  // Buttons Next / Prev:
  .react-datepicker__navigation {
    display: flex;
    padding: 4px 8px;
    margin: 0;
    border-radius: 2px;
    top: 12px;
    height: 24px;
    align-items: center;
    border-color: #ccc !important;

    &:focus {
      outline: 2px solid settings.$color-primary;
    }
  }

  .react-datepicker__year-option:has(
      > .react-datepicker__navigation.react-datepicker__navigation--years
    ) {
    padding-left: 16px;
  }

  .react-datepicker__year-dropdown:has(
      > .react-datepicker__year-option:first-child[aria-selected]
    ):has(> .react-datepicker__year-option:last-child > a) {
    padding-top: 46px;
    cursor: default;
  }

  .react-datepicker__year-dropdown:has(
      > .react-datepicker__year-option:last-child[aria-selected]
    ):has(> .react-datepicker__year-option:first-child > a) {
    padding-bottom: 46px;
    cursor: default;
  }

  .react-datepicker__navigation--previous {
    left: 8px;
  }

  .react-datepicker__navigation--next {
    right: 8px;
  }

  // Icon Buttons Next / Prev
  .react-datepicker__navigation-icon {
    width: 16px;
    height: 16px;
    margin-top: 0;
    position: relative;

    left: auto;
    right: auto;
    display: flex;
    justify-content: center;
    font-size: 0;

    &::before {
      border-width: 2px 2px 0 0;
    }
  }

  .react-datepicker__navigation-icon--previous {
    &::before {
      right: auto;
      left: 5px;
    }
  }

  .react-datepicker__navigation-icon--next {
    &::before {
      left: auto;
      right: 5px;
    }
  }

  .react-datepicker__year-option {
    .react-datepicker__navigation.react-datepicker__navigation--years {
      width: 100%;
      position: static;

      &::after {
        content: '';
        display: block;
        border-color: #ccc;
        border-style: solid;

        height: 9px;
        width: 9px;

        order: 2;
        border-width: 2px 2px 0 0;
      }

      &.react-datepicker__navigation--years-upcoming::after {
        transform: rotate(-45deg);
        margin-top: 4px;
      }
      &.react-datepicker__navigation--years-previous::after {
        transform: rotate(135deg);
        margin-top: -4px;
      }
    }
  }

  // Header:
  .react-datepicker__header {
    background-color: settings.$color-neutral-light;
    border-bottom: 0 solid settings.$transparent;
    padding: (settings.$spacing-small * 1.5) 0 0;
  }

  // First Row:
  .react-datepicker__header__dropdown {
    display: flex;
  }

  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
    margin-bottom: 12px;
  }

  .react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--scroll {
    margin-left: 40px;
    min-width: 102px;
  }

  .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--scroll {
    margin-left: auto;
    margin-right: 40px;
  }

  // Trigger dropdown menu's Month & Years:
  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--scroll,
  .react-datepicker__month-year-dropdown-container--scroll {
    margin: 0 12px;
    position: relative;
  }

  .react-datepicker__month-read-view,
  .react-datepicker__month-year-read-view,
  .react-datepicker__year-read-view {
    margin-right: settings.$spacing-small * 2.5;
  }

  .react-datepicker__month-read-view,
  .react-datepicker__year-read-view {
    display: flex;
    padding: 4px 8px;
    margin: 0;
    border-radius: 2px;
    min-height: 24px;
    align-items: center;
    text-transform: uppercase;
    font-family: 'latolight';

    justify-content: center;

    @include settings.font-size(11px);
  }

  // Trigger dropdowns Month & Year Arrow
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    position: relative;
    right: 0;
    margin-left: 16px;
    margin-top: -5px;
    order: 2;
    border-width: 2px 2px 0 0;
  }

  .react-datepicker__month-read-view--selected-month {
    margin-right: -4px;
  }

  .react-datepicker__year-read-view--selected-year {
    margin-right: -4px;
  }

  // Triggers dropdown
  // Important: because we don't hide the month or year menu when open:
  .react-datepicker__month-dropdown
    + .react-datepicker__month-read-view[style='visibility: hidden;'],
  .react-datepicker__year-dropdown
    + .react-datepicker__year-read-view[style='visibility: hidden;'] {
    visibility: visible !important;
    outline: 2px solid settings.$color-primary;
  }

  // Menu
  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background-color: settings.$color-neutral-x-light;
    box-shadow: settings.$menu-box-shadow;

    width: 160px;
    left: -1px;
    top: settings.$spacing-large;
    text-align: left;
    border-radius: 4px;
    padding: 4px 0;
  }

  .react-datepicker__year-dropdown {
    right: -1px;
    left: auto;
    //   max-height: 240px;
  }

  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    left: 0;
    margin-right: 10px;
    padding-left: settings.$spacing-medium;
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option,
  .react-datepicker__month-year-option {
    border: 1px solid settings.$transparent;
    background: none;
    text-decoration: none;
    text-transform: capitalize;

    font-family: settings.$font-family-regular;

    border-radius: 0;
    width: 100%;
    text-align: left;
    color: settings.$color-neutral-dark;
    font-weight: normal;

    position: relative;

    padding: settings.$spacing-small settings.$spacing-medium;
    padding-left: settings.$spacing-large + settings.$spacing-small;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: default;

    @include settings.font-size(13px, 1.2);

    &:hover,
    &:active,
    &:focus {
      background-color: settings.$color-primary-10; // == button--light
    }

    &.is-active {
      & {
        background-color: inherit; // == button--light
      }
    }

    .c-icon {
      position: absolute;
      left: settings.$spacing-medium;
      right: auto;
      top: settings.$spacing-small;
      width: settings.$small-icon;
      height: settings.$small-icon;
    }

    span {
      width: 100%;
    }

    .c-accordion & span {
      width: auto;
      flex: 1 1 auto;
    }
  }

  .react-datepicker__year-option.react-datepicker__year-option--selected_year,
  .react-datepicker__month-option.react-datepicker__month-option--selected_month {
    color: settings.$color-primary-dark;
  }

  // The second row with day names:
  .react-datepicker__day-names {
    margin-bottom: 0;
    padding: 2px 0 2px;
    background: #f9f9f9;
    box-shadow: 0 1px 9px -7px;
  }

  .react-datepicker__day-name {
    @include settings.font-size(13px, 1.2);
    text-transform: capitalize;
    font-family: settings.$font-family-light;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 4px;
  }

  // Month === the square where the actual month is shown
  .react-datepicker__month {
    margin: 12px;
  }

  // Days styles
  .react-datepicker__day {
    &,
    &:hover,
    &:focus {
      border-radius: 50%;
    }
  }

  .react-datepicker__day:hover,
  .react-datepicker__day--keyboard-selected {
    background-color: settings.$color-neutral-light;
    outline: 1px solid settings.$color-neutral;

    &.react-datepicker__day--disabled:not(.react-datepicker__day--today) {
      background-color: settings.$transparent;
      outline: 0;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selected:hover {
    border-radius: 50%;

    background-color: settings.$color-neutral-x-light;
    color: settings.$color-neutral-x-dark;
    outline: 2px solid settings.$color-primary;
    outline-offset: -1px;
    cursor: default;
  }

  .react-datepicker__day--today,
  .react-datepicker__day--today:hover {
    outline: 1px dashed settings.$color-neutral;

    &.react-datepicker__day--disabled {
      background-color: settings.$transparent;
    }
  }

  .react-datepicker__day--highlighted-finished {
    &,
    &.react-datepicker__day--selected,
    &:hover {
      background-color: settings.$color-success;
      color: settings.$color-neutral-x-light;
      font-family: settings.$font-family-bold;
    }

    &.react-datepicker__day--selected {
      outline: 2px solid settings.$color-primary;
      outline-offset: -1px;
    }

    &:hover {
      outline: 2px solid settings.$color-success;
      background-color: settings.$color-neutral-light;
      color: settings.$color-success;
      outline-offset: -1px;
    }
  }

  .react-datepicker__day--highlighted-unfinished {
    outline: 1px solid rgba(settings.$color-success, 0.25);
    position: relative;

    &::after {
      content: '';
      background: settings.$color-success;
      width: 3px;
      height: 3px;
      position: absolute;
      bottom: 2px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 3px;
    }

    &:hover {
      outline: 1px solid rgba(settings.$color-success, 0.25);
    }

    &.react-datepicker__day--selected {
      outline: 2px solid settings.$color-primary;
      outline-offset: 0;
    }
  }
}
