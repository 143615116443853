@use '_scss/settings';
@use 'sass:math';
/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

@font-face {
  font-family: 'latoblack';
  src: url('~/src/assets/fonts/lato-black-webfont.eot');
  src:
    url('~/src/assets/fonts/lato-black-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('~/src/assets/fonts/lato-black-webfont.woff2') format('woff2'),
    url('~/src/assets/fonts/lato-black-webfont.woff') format('woff'),
    url('~/src/assets/fonts/lato-black-webfont.ttf') format('truetype'),
    url('~/src/assets/fonts/lato-black-webfont.svg#latoblack') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'latolight';
  src: url('~/src/assets/fonts/lato-light-webfont.eot');
  src:
    url('~/src/assets/fonts/lato-light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('~/src/assets/fonts/lato-light-webfont.woff2') format('woff2'),
    url('~/src/assets/fonts/lato-light-webfont.woff') format('woff'),
    url('~/src/assets/fonts/lato-light-webfont.ttf') format('truetype'),
    url('~/src/assets/fonts/lato-light-webfont.svg#latolight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lato';
  src: url('~/src/assets/fonts/lato-regular-webfont.eot');
  src:
    url('~/src/assets/fonts/lato-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('~/src/assets/fonts/lato-regular-webfont.woff2') format('woff2'),
    url('~/src/assets/fonts/lato-regular-webfont.woff') format('woff'),
    url('~/src/assets/fonts/lato-regular-webfont.ttf') format('truetype'),
    url('~/src/assets/fonts/lato-regular-webfont.svg#latoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: math.div(settings.$inuit-global-font-size, 16px) * 1em; /* [1] */
  line-height: math.div(
    settings.$inuit-global-line-height,
    settings.$inuit-global-font-size
  ); /* [1] */
  // overflow-y: scroll; /* [2] */
  overflow-x: hidden;
  min-height: 100%; /* [3] */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: settings.$font-family-regular;
}

body {
  height: 100dvh;
  min-height: 100%;
  background: white;
  margin: 0;

  #root {
    height: inherit;
    min-height: inherit;
  }
}

body.blue {
  background: #071649;
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

b {
  font-family: settings.$font-family-bold;
  font-weight: normal;
}
