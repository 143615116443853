@use '_scss/settings';
/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

// We have all of our heading font sizes defined here. Passing these pixel
// values into our `inuit-font-size()` mixin will generate a rem-based
// `font-size` with a pixel fallback, as well as generating a `line-height` that
// will sit on our baseline grid.

$inuit-font-size-h1: 36px !default;
$inuit-font-size-h2: 28px !default;
$inuit-font-size-h3: 24px !default;
$inuit-font-size-h4: 20px !default;
$inuit-font-size-h5: 18px !default;
$inuit-font-size-h6: 16px !default;

h1 {
  @include settings.font-size($inuit-font-size-h1);
  font-weight: normal;
}

h2 {
  @include settings.font-size($inuit-font-size-h2);
  font-weight: normal;
}

h3 {
  @include settings.font-size($inuit-font-size-h3);
  font-weight: normal;
}

h4 {
  @include settings.font-size($inuit-font-size-h4);
  font-weight: normal;
}

h5 {
  @include settings.font-size($inuit-font-size-h5);
  font-weight: normal;
}

h6 {
  @include settings.font-size($inuit-font-size-h6);
  font-weight: normal;
}
