@use '_scss/settings';
@use '_scss/07-plugins/mq' with (
  $breakpoints: settings.$mq-breakpoints
);

.c-accordion {
  position: relative;
  width: 100%;
  overflow: hidden;
  user-select: none;

  padding: settings.$spacing-tiny;
  margin: 0 (-#{settings.$spacing-tiny});
  min-width: calc(100% + #{settings.$spacing-small});
}

.c-accordion-options {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.c-accordion-options--in-modal {
  .c-card-modal__content-body-max-height .c-card__body > & {
    margin-bottom: settings.$spacing-medium * 1.5;
  }
}

.c-accordion--visible-flow {
  overflow: visible;

  .c-accordion__content {
    overflow: visible;
  }

  .c-accordion__button:not(.c-accordion__button-custom):checked
    ~ .c-accordion__content {
    overflow: hidden;
  }
}

.c-accordion--cogwheel {
  margin-top: -48px;

  > .c-accordion__button:not(.c-accordion__button-custom)
    + .c-accordion__label.c-accordion__label-selfclosing {
    min-height: 32px;
    display: block;
    align-items: center;

    z-index: 10;
    width: 32px;
    height: 40px;
    margin-left: auto;

    .c-accordion__content {
      margin-top: 32px;
      margin-bottom: -16px;
      position: relative;
      top: -32px;
    }

    &::after {
      right: 6px;
      left: auto;
      top: -4px;
      background-image: url('~/src/assets/images/svgstore/settings-gray.svg');
    }
  }

  > label > span {
    display: none;
  }
}

.c-card__body--modal {
  .c-accordion--cogwheel {
    margin-top: 0;

    > .c-accordion__button:not(.c-accordion__button-custom) {
      display: none;
    }

    > .c-accordion__button:not(.c-accordion__button-custom)
      + .c-accordion__label.c-accordion__label-selfclosing {
      display: none;
    }
  }
}

.c-accordion--cogwheel.c-accordion--small {
  margin-top: -36px;

  > .c-accordion__content {
    background: settings.$color-neutral-light;
    padding: 0 settings.$spacing-medium !important;
  }

  > .c-accordion__button:not(.c-accordion__button-custom)
    + .c-accordion__label.c-accordion__label-selfclosing {
    height: 32px;

    &::after {
      top: -1px;
    }
  }

  > .c-accordion__content {
    > .c-input-checkbox--with-paragraph:first-child {
      margin-top: settings.$spacing-small * 1.5;
    }

    > :last-child {
      margin-bottom: settings.$spacing-small * 1.5 !important;
    }
  }
}

.c-accordion--switch {
  .c-accordion__content > & {
    min-width: 100%;
    margin: 0;
    padding: 0;
  }

  > .c-accordion__content:not(.o-layout) {
    padding: 0 settings.$spacing-medium !important;
    background-color: settings.$color-neutral-x-light;
  }

  > .c-accordion__content {
    > .c-input-checkbox--with-paragraph:first-child {
      margin-top: settings.$spacing-small !important;
    }
    > :last-child {
      margin-bottom: settings.$spacing-medium !important;
    }
  }

  .c-card__header-title--small {
    margin-bottom: 8px;
  }

  .c-accordion-options:not(.c-accordion-options--in-modal)
    &
    .c-input-checkbox--with-paragraph {
    margin-left: -12px;
    width: calc(100% + 24px);
  }

  > .c-accordion__button:not(.c-accordion__button-custom) {
    & + .c-accordion__label {
      border: 2px solid settings.$transparent;
    }

    &:focus + .c-accordion__label {
      border-color: settings.$color-primary;
      outline: 0;
    }
  }

  > .c-accordion__button:not(.c-accordion__button-custom)
    + .c-accordion__label.c-accordion__label-selfclosing {
    padding-right: 56px;
    min-height: 40px;
    display: flex;
    align-items: center;
    font-family: settings.$font-family-regular;
    color: settings.$color-primary-dark;
    margin-top: settings.$spacing-tiny;

    span {
      margin-left: auto;
    }

    &::after {
      //   right: 6px;
      //   left: auto;
      //   top: -4px;
      width: 40px;
      height: 40px;
      left: auto;
      right: settings.$spacing-small;
      background-image: url('~/src/assets/images/svgstore/toggle-off.svg');
      top: 50%;
      transform: translateY(-50%);
    }
  }

  > .c-accordion__button:not(.c-accordion__button-custom):checked
    + .c-accordion__label:after {
    background-image: url('~/src/assets/images/svgstore/toggle-on.svg');
    // transform: rotate(270deg);
  }

  // @ InjuryDetailFields.js
  .c-card--secondary & {
    margin-left: -#{settings.$spacing-small};
    min-width: calc(100% + #{settings.$spacing-medium});
    padding: 0;
    margin-bottom: settings.$spacing-small * 1.5;

    > .c-accordion__content {
      background: inherit;
      padding: 0 !important;
    }
  }

  .c-card__body--modal-with-overflow & {
    // .c-accordion__label,
    // .c-accordion__button {
    //   display: none !important;
    // }
    > .c-accordion__button:not(.c-accordion__button-custom)
      + .c-accordion__label.c-accordion__label-selfclosing {
      margin-left: 10px;
      width: auto;
      margin-right: 10px;
      border-radius: 4px;
    }

    .c-accordion {
      width: 100% !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    > .c-accordion__content .o-layout__item {
      padding-left: settings.$spacing-small;
      padding-right: settings.$spacing-small;
    }

    .c-input-checkbox--with-paragraph {
      margin: 0;

      .c-input__label--checkbox {
        border: 2px solid transparent;
      }

      .c-input--checkbox:focus + .c-input__label--checkbox {
        border-color: settings.$color-primary;
        border-radius: 2px;
      }

      &:focus-within {
        outline: 0;
      }
    }
  }
}

.c-accordion__label {
  position: relative;
  cursor: pointer;
  user-select: inherit;
  // background: settings.$color-neutral-light;
  color: settings.$color-neutral-dark;
  font-family: settings.$font-family-bold;
  padding: settings.$spacing-small settings.$spacing-large;
  z-index: 1;
  display: block;
  width: 100%;

  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;

  .c-card__header--secondary {
    color: settings.$color-primary-dark;
  }

  @include settings.font-size(13px, 1.4);

  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 100%;
    top: 0;
    left: settings.$spacing-small;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    // transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
  }

  &.c-accordion__label-selfclosing {
    padding: 0;

    .c-card__header {
      max-width: calc(100% - #{settings.$spacing-medium * 1.5});
      margin-bottom: 0;
    }

    &::after {
      right: 0;
      left: auto;
    }

    ~ .c-accordion__content {
      padding-left: 0;

      > *:first-child {
        margin-top: settings.$spacing-medium;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.c-accordion__label-table::after {
    right: auto;
    left: calc(100% - 28px);
    top: -27px;

    @include mq.mq($from: small) {
      left: calc(40% - 24px);
      top: 0;
    }

    @media screen and (min-width: 900px) and (max-width: 953px) {
      left: calc(100% - 28px);
      top: -27px;
    }
  }
}

.c-accordion__label-empty {
  width: 25px;
  padding: 0;
  height: 34px;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;

  & + .c-accordion__label.c-button--menu {
    padding-left: 4px !important;
    font-family: 'latoblack';
    margin: 0 0 0 28px;
    width: calc(100% - 28px);
    display: flex;
    padding-right: 16px;

    &::after {
      content: none;
    }

    &.c-button--disabled,
    &.c-button--disabled:hover {
      background: settings.$transparent !important;

      use {
        stroke: settings.$color-neutral;
      }
    }
  }
}

.c-accordion__content {
  padding-left: settings.$spacing-large - settings.$spacing-tiny;

  .c-accordion__label {
    &::after {
      left: 0;
    }
  }
}

.c-accordion__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;

  line-height: 2;
  margin: 0;

  .c-button--menu {
    padding-left: settings.$spacing-large;

    .c-icon {
      left: settings.$spacing-tiny;
    }
  }

  .c-button--disabled,
  .c-button--disabled:hover {
    background: settings.$transparent !important;

    use {
      stroke: settings.$color-neutral;
    }
  }

  .c-list__item use {
    stroke: none;
  }

  .c-list__item {
    line-height: 1.25;
  }
}

.c-accordion__button:not(.c-accordion__button-custom) {
  position: absolute;
  opacity: 0;
  z-index: -1;

  & ~ .c-accordion__content {
    max-height: 0vh;
  }

  & + .c-accordion__label:after {
    background-image: url('~/src/assets/images/svgstore/16-dropdown-closed.svg');
    transform: rotate(180deg);
  }

  &:checked + .c-accordion__label:after {
    background-image: url('~/src/assets/images/svgstore/16-dropdown-closed.svg');
    transform: rotate(270deg);
  }

  &:checked ~ .c-accordion__content {
    max-height: 1000vh;
  }

  &:focus + .c-accordion__label {
    outline-offset: 2px;
    outline: 2px solid settings.$color-primary;
  }
}

.c-accordion__button:not(.c-accordion__button-custom) {
  &:checked + .c-accordion__label-table {
    outline: 1px solid settings.$color-primary-25;
    background:
      linear-gradient(
        rgba(settings.$color-primary, 0.1),
        rgba(settings.$color-primary, 0.1)
      ),
      linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));

    .c-table--results-left-right.c-table--report-tab
      .c-table__body
      .c-table-cell.c-table-cell--even-half {
      background:
        linear-gradient(
          rgba(settings.$color-primary, 0.1),
          rgba(settings.$color-primary, 0.1)
        ),
        linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
    }
  }

  & + .c-accordion__label-table ~ .c-accordion__content {
    padding: 0;
    box-shadow: none;
    // border: transparent;

    margin: 0 0 4px !important;

    // background: white;
    z-index: 10;
    position: relative;
    box-shadow: 0 0 10px -5px;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;

    .c-list {
      margin: 0 auto !important;
      padding-bottom: 24px;
      width: calc(100% - 32px);

      &:first-of-type .c-list__header {
        padding-top: 16px;
      }
    }
  }

  &:checked + .c-accordion__label-table ~ .c-accordion__content {
    // padding: 16px !important;
    // border: 1px solid settings.$color-primary-25;
    outline: 1px solid settings.$color-primary-25;

    // border-top: none;
  }
}

.c-accordion--visible-flow {
  .c-accordion__content {
    overflow: hidden;
  }

  .c-accordion__button:not(.c-accordion__button-custom) {
    &:checked ~ .c-accordion__content {
      overflow: visible;
    }
  }
}

.c-accordion__button-no-child {
  display: none;
  &::after {
    content: none;
  }
}

$custom-height: 48px;

.c-accordion--custom-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 0;
  max-height: 100%;
  // padding-top: settings.$spacing-tiny;
  // padding-bottom: settings.$spacing-tiny;
  // transition: margin-left 2s ease-in-out, max-height 3s ease-in-out 0.2s;

  .c-button--accordion-back {
    display: none;
    // position: absolute;
    // top: 8px;
    // left: 8px;
    opacity: 0;
    // transition: opacity 2s ease-out;
  }

  .c-input__group-radiobutton-org {
    margin: 0;
    min-height: $custom-height;
    display: flex;
    align-items: center;
    flex: 1;
    opacity: 1;
    flex-wrap: wrap;
    position: relative;
    flex: 1 1 auto;
    padding-left: settings.$spacing-tiny;
    // width: calc(100% - 80px);

    // transition: margin-left 2s ease-in-out, width 2s ease -2s;
  }

  .c-accordion__content {
    // transition: padding-left 2s ease, max-height 3s ease-in-out 0.2s;
    width: 100%;
  }
}

.c-accordion__button-custom {
  @include settings.inuit-hidden-visually();

  & + .c-accordion__label-custom {
    &::after {
      margin-left: settings.$spacing-small;
      transform: rotate(0deg);
      pointer-events: none;
    }
  }

  &:checked + .c-accordion__label-custom {
    &::after {
      transform: rotate(-90deg);
    }

    & + .c-accordion__content {
      max-height: 1000vh;
    }
  }
}

// Class added to parent wrapper
.c-accordion--custom-wrapper-subleveled {
  // padding-top: 45px;
  // padding-top: 0;

  > .c-accordion__back-button {
    min-height: $custom-height;
    // position: absolute;
    position: relative;
    // top: 45px;
    top: 0;
    left: 0;
    height: 100%;
    //width: auto;
    max-width: 100%;
    // border: 1px solid orange;

    width: 100%;
    display: flex;
    align-items: center;
    padding-left: settings.$spacing-medium;
    margin: 0 0 0 100%;

    .c-button--accordion-back {
      display: block;
      opacity: 1;
      text-align: left;
      width: auto;
      position: relative;
      top: 0;
      left: 0;
    }

    @include mq.mq($from: small) {
      padding-left: settings.$spacing-small;
    }
  }

  > .c-accordion__content.content-open {
    // padding-top: settings.$spacing-large + settings.$spacing-medium;
    padding-left: settings.$spacing-small;
  }

  .is-storybook & > .c-accordion__back-button {
    margin: 0;
  }
}

.c-accordion--list-items {
  .c-accordion__label {
    position: absolute;
    top: 0;
  }
}

.content-open {
  > .c-accordion.is-open {
    margin-left: 0;

    .content-open {
      > .c-accordion:not(.is-open) {
        border: 0;
      }
    }
  }
}

.c-accordion__back-button {
  width: 100%;

  .c-icon {
    transform: rotate(90deg) scaleY(-1);
  }
}

.c-accordion__label-custom {
  left: auto;
  right: 0;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: $custom-height;
  padding: 0;
  margin: 0;

  font-family: settings.$font-family-light;
  font-size: 13px;

  &::after {
    background-image: url('~/src/assets/images/svgstore/16-dropdown-closed.svg');
    transform: rotate(180deg);
    // height: 100%;
    position: relative;
    // left: auto;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: 0;

    width: 20px;
    height: 20px;
    display: block;
  }
}

.c-input__group-radiobutton-org {
  margin-bottom: 0;
  margin-left: 0;
  display: flex;
  align-items: center;
}

.another-layer {
  opacity: 0.03;
  position: absolute;
  max-width: 100%;
  left: 0;
  padding: 0 16px;

  display: none;
}

.open-layer {
  &.is-open {
    // color: orange;
    > .c-accordion--custom-wrapper-subleveled > .content-open {
      top: 0;
      padding-left: 8px;
    }
  }

  // Class added to parent radiobutton
  .c-accordion--custom-wrapper > .c-input__group-radiobutton-org-removed {
    // background: rgba(0, 0, 0, 0.1);
    order: -1;
    // width: 200%;
    padding-left: 0;
    padding-top: 3px;
    z-index: 0;
    position: relative;

    // margin-top: -93px !important;
    margin-left: calc(-100% - 4px);

    & ~ .c-accordion__label-custom {
      opacity: 0.5;
      position: relative;
      // top: -45px;
      color: blue;
      // visibility: hidden;
      // opacity: 0;
      display: none;
    }

    .c-input__label {
      color: gray;
      // &::before,
      // &::after {
      //   content: none !important;
      // }
    }

    .content-open {
      padding-left: 0 !important;
    }
  }
}

.open-layer:not(.is-open) {
  > .c-accordion--custom-wrapper > .c-input__group-radiobutton-org-removed {
    opacity: 0.03;
    margin-top: 0 !important;

    & ~ .c-accordion__label-custom {
      top: 0;
      opacity: 0.1;
      display: none;
    }
  }

  .content-open {
    padding-left: 0px;
    position: relative;
    // top: -45px;
    top: 0;
    border: 0;
  }
}

.c-accordion__back-label-name {
  font-family: settings.$font-family-bold;
  color: settings.$color-primary-dark;
  text-transform: uppercase;
  display: inline-block;
  margin-left: settings.$spacing-small;
}
// Virtualized list in accordion:

.c-accordion__button:not(.c-accordion__button-custom)
  + .c-accordion__label-table
  ~ .c-accordion__content
  .c-accordion__virtualized-list-wrapper {
  height: 330px;
  padding-bottom: 0;
  margin-top: 0;

  .c-list--virtualized {
    height: 100%;
    width: 100%;
    padding: settings.$spacing-medium settings.$spacing-medium
      (settings.$spacing-small * 1.5);
  }
}
